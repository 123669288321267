<template>
  <div class="row">
    <div class="col-12 mb-3" v-for="team in teams" :key="team">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-2">
              <Tshirt :color="team.color" />
            </div>
            <div class="col-9">
              <h4 class="mb-0">{{ team.name }}</h4>
              <p class="mb-0">{{ team.sortname }}</p>
            </div>
            <div class="col-1">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  @change="senddata"
                  v-model="selectedteams"
                  :value="{id:team.id, did:team.did, name:team.name, sortname:team.sortname, color:team.color, platforms:team.platforms}"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12" style="text-align:center">
      <button class="btn bg-gradient-secondary" @click="getdata()">Load More</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

import Tshirt from "../teams/Tshirt.vue";

export default {
  components: { Tshirt },
  props: ["sport", "seriesid", "seriesname", "selectedteams"],
  data() {
    return {
      limit: 0,
      teams: [],
    };
  },
  created() {
    this.getdata();
  },
  watch: {
    sport: function (newVal, oldVal) {
      this.getdata();
    },
  },
  methods: {
    getdata() {
      this.limit = this.limit + 50;
      const db = firebase.firestore();
      db.collection("teams")
        .where("seriess", "array-contains", {
          id: this.seriesid,
          name: this.seriesname,
        })
        .limit(this.limit)
        .onSnapshot((querySnapshot) => {
          var teamstemp = [];
          querySnapshot.forEach((doc) => {
            teamstemp.push(doc.data());
          });
          this.teams = teamstemp;
        });
    },
    senddata() {
      this.$emit("senddata", this.selectedteams);
    },
  },
};
</script>

<style>
</style>