<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-2">
          <img v-if="player.img == ''" src="../../../assets/img/default-player-image.png" alt />
          <img v-else :src="player.img" alt />
        </div>
        <div class="col-10">
          <h5>
            <b>{{ player.name }}</b>
          </h5>
          <div class="table-responsive">
            <table class="table align-items-left mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Credits</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Points</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Selection Rate</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Team</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.credits }}</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.points }}</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.selectionRate }}%</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.team }}</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.type }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr class="mt-4" />
        <div class="col-12">
          <div class="table-responsive">
            <table class="table align-items-left mb-0">
              <thead>
                <tr>
                  <th class="text-secondary opacity-7"></th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Dream11</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Myteam11</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >My11Circle</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Player ID</td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.platforms.dream11.id }}</p>
                  </td>
                  <td>
                    <p
                      v-if="player.platforms.myteam11 != undefined"
                      class="text-xs font-weight-bold mb-0"
                    >{{ player.platforms.myteam11.id }}</p>
                  </td>
                  <td>
                    <p
                      v-if="player.platforms.my11circle != undefined"
                      class="text-xs font-weight-bold mb-0"
                    >{{ player.platforms.my11circle.id }}</p>
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Player Credits</td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ player.platforms.dream11.credits }}</p>
                  </td>
                  <td>
                    <p
                      v-if="player.platforms.myteam11 != undefined"
                      class="text-xs font-weight-bold mb-0"
                    >{{ player.platforms.myteam11.credits }}</p>
                  </td>
                  <td>
                    <p
                      v-if="player.platforms.my11circle != undefined"
                      class="text-xs font-weight-bold mb-0"
                    >{{ player.platforms.my11circle.credits }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-primary w-100 mb-0 mt-3"
            @click="funselcator('bottomp')"
          >Send To Bottom</button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-danger w-100 mb-0 mt-3"
            @click="funselcator('deleteplayer')"
          >Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  props: ["player", "index"],
  data() {
    return {};
  },
  methods: {
    funselcator(funtext) {
      this.$emit(funtext, this.index);
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100px;
}
.table thead th {
  padding: 0.5rem 0.5rem;
}
</style>