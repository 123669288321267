<template>
  <div class="row">
    <div class="col-xl-3 col-sm-6 mb-4" v-for="other in otherPrediction" :key="other">
      <div class="card">
        <div class="card-body p-3 d-flex align-items-center">
          <div class="avatar me-3">
            <img :src="other.image" alt="kal" class="border-radius-lg shadow" />
          </div>
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">{{ other.name }}</h6>
            <p class="mb-0 text-xs">{{ other.url }}</p>
          </div>
          <button type="button" class="btn btn-link pe-3 ps-0 mb-0 ms-auto" @click="removePreditionsCompnay(other)">Delete</button>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="docnew != null">
      <div class="card h-100">
        <div class="card-header pb-0 p-3">
          <h6 class="mb-0">Other Prediction</h6>
        </div>
        <div class="card-body p-3">
          <form @submit.prevent="saveOtherPrediction">
            <div class="row">
              <div class="col-3">
                <label>Image</label>
                <div class="mb-3">
                  <input type="file" class="form-control" placeholder="Other Image Id" @change="previewImage" accept="image/jpg" required />
                </div>
              </div>
              <div class="col-3">
                <label>Other Prediction Name</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Other Name" v-model="name" required />
                </div>
              </div>
              <div class="col-3">
                <label>Other Prediction URL</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Other Name" v-model="url" required />
                </div>
              </div>
              <div class="col-3">
                <label>‎</label>
                <div class="mb-3">
                  <button type="submit" class="btn bg-gradient-info mb-0 w-100">
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>.
  </div>
</template>


<script>
import firebase from "firebase";
import SwlAlerts from "../../swlalerts.js";
import database from "../../databasefuncation";

export default {
  async created() {
    await this.getData();
  },

  data() {
    return {
      otherPrediction: [],
      name: "",
      url: "",
      imageData: "",
      imageurl: "",
      docnew: null,
    };
  },
  methods: {
    previewImage(event) {
      //   this.picture = URL.createObjectURL(event.target.files[0]);
      this.imageData = event.target.files[0];
    },

    async getData() {
      var db = firebase.firestore();

      var path = db.collection("manage_app").doc("predictionData");

      var data = await database.getdoc(path);

      if (data != undefined) {
        this.otherPrediction = data.otherPrediction;
        this.docnew = false;
      } else {
        this.docnew = true;
      }
    },

    async uploadFile() {
      var imgurl;
      var user = firebase.auth().currentUser;
      await firebase
        .storage()
        .ref("/preditionCompany/" + user.uid + "/" + this.name + ".jpg")
        .put(this.imageData)
        .then(async (response) => {
          await response.ref
            .getDownloadURL()
            .then((downloadURL) => {
              imgurl = downloadURL;
            })
            .catch((err) => SwlAlerts.swlerror(err.message));
        });
      return imgurl;
    },

    async saveOtherPrediction() {
      SwlAlerts.swlloading();
      this.imageurl = await this.uploadFile();
      if (this.docnew) {
        var object = {
          otherPrediction: [{
            url: this.url,
            name: this.name,
            image: this.imageurl,
          }]
        };
        await database.savedoc(object, "manage_app", "predictionData");
      } else {
        var object = {
          otherPrediction: firebase.firestore.FieldValue.arrayUnion({
            url: this.url,
            name: this.name,
            image: this.imageurl,
          }),
        };
        await database.updatedoc(object, "manage_app", "predictionData");
      }
      await this.getData();
    },

    async removePreditionsCompnay(data) {
      SwlAlerts.swlloading();

      var user = firebase.auth().currentUser;

      firebase
        .storage()
        .ref("/preditionCompany/" + user.uid + "/" + data.name + ".jpg")
        .delete()
        .then( async () => {

          var object = {
            otherPrediction: firebase.firestore.FieldValue.arrayRemove(data),
          };
          await database.updatedoc(object, "manage_app", "predictionData");

          await this.getData();

        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });

    },
  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>