<template>
  <!-- <nav class="navbar navbar-vertical mb-3 p-0">
    <ul class="navbar-nav">
      <li class="nav-item" v-for="sport in sports" :key="sport">
        <a
          href="javascript:void(0)"
          v-on:click="changetab(sport.id)"
          :class="{'nav-link' : true, active: (sport.id == activeSport)}"
        >{{sport.name}}</a>
      </li>
    </ul>
  </nav>-->

  <div class="row">
    <div class="col-6">
      <label>Select Sport</label>
      <div class="mb-3">
        <select class="form-control" v-model="activeSport" @change="sportSelected">
          <option value="">Select Sport</option>
          <option :value="sport.id" v-for="sport in sports" :key="sport">{{ sport.name }}</option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <label>Select Series</label>
      <div class="mb-3">
        <select class="form-control" v-model="activeSeries" @change="getdata">
          <option :value="{}">Select Series</option>
          <option :value="serie" v-for="serie in series" :key="serie">{{ serie.name }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-4 mb-4" v-for="team in teams" :key="team">
      <TeamsPreview
        :team="team"
        :action="action"
        :sports="sports"
      />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

import TeamsPreview from "../../components/teams/Team-Preview.vue";

export default {
  components: { TeamsPreview },
  data() {
    return {
      teams: [],
      action: true,
      sports: [],
      activeSport: "",
      series: [],
      activeSeries: {},
      teams : [],
    };
  },
  async created() {
    const db = firebase.firestore();

    var path = db.collection("sports").orderBy("position");

    this.sports = await database.getalldocs(path);
  },
  methods: {
    async sportSelected() {
      const db = firebase.firestore();

      var path = db.collection("series").where("sport", "==", this.activeSport);

      this.series = await database.getalldocs(path);
    },

    async getdata() {
      const db = firebase.firestore();

      var teamsPath = db
        .collection("teams")
        .where("seriess", "array-contains", {
          id: this.activeSeries.id,
          name: this.activeSeries.name,
        });

      teamsPath.onSnapshot((querySnapshot) => {
        var teamstemp = [];
        querySnapshot.forEach((doc) => {
          teamstemp.push(doc.data());
        });
        this.teams = teamstemp;
      });
    },
  },
};
</script>


<style scoped>
.navbar {
  display: block;
  box-shadow: none;
}
.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-nav .nav-item {
  width: 100%;
}
.navbar-nav .nav-item .nav-link {
  text-align: center;
  display: block;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>