<template>
  <div class="card">
    <div class="card-body p-3">
      <div class="row mb-3">
        <div class="col-4" v-for="pextr in data.pitchExtra" :key="pextr">
          <div class="d-flex px-2 py-1">
            <div>
              <img :src="require(`@/assets/img/prediction/${pextr.id}.svg`)" class="avatar avatar-sm me-3" alt="user1">
            </div>
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-sm">{{ pextr.name }}</h6>
              <p class="text-xs text-secondary mb-0">{{ pextr.data }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row  mb-3">
        <div class="col-4" v-for="eInfo in data.extraInfo" :key="eInfo">
          <h6 class="mb-0 text-sm">{{ eInfo.name }}</h6>
          <p class="text-xs text-secondary mb-0">{{ eInfo.data }}</p>
        </div>
      </div>
      <div class="picthDat" v-html="data.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
}
</script>

<style>
</style>