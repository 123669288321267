<template>
  <AddEditPrediction :isnew="isnew" />
</template>

<script>

import AddEditPrediction from "../../components/prediction/Add-Edit-Prediction.vue"

export default {
  components: { AddEditPrediction },
  data() {
    return {
      isnew: true,
    };
  },
};
</script>

<style>
</style>