<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }" :id="add_other_prediction" tabindex="-1" role="dialog" :aria-labelledby="add_other_prediction"
        aria-hidden="true">
        <div v-if="active" class="modal-dialog modal- modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">{{ data.name }}</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-6">
                            <h4>Extra Pitch</h4>
                        </div>
                        <div class="col-6">
                            <form @submit.prevent="addpitchExtra" class="d-flex align-items-center justify-content-end">
                                <select class="form-control mr-2" v-model="activepitchExtra" required style="margin-right: 0.5rem !important;">
                                    <option :value="null">Select Extra</option>
                                    <option :value="pExtra" v-for="pExtra in pitchExtra" :key="pExtra">{{ pExtra.name }}</option>
                                </select>
                                <button type="submit" class="btn bg-gradient-success mb-0">Add</button>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-6" v-for="(pextr, index) in data.data.pitchExtra" :key="pextr">
                            <label>{{ pextr.name }}</label>
                            <div class="row">
                                <div class="col-8">
                                    <input class="form-control" type="text" v-model="data.data.pitchExtra[index].data">
                                </div>
                                <div class="col-4">
                                    <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;" @click="deletepitchExtra(index)"><i class="far fa-trash-alt me-2"
                                            aria-hidden="true"></i>Delete</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-6">
                            <h4>Info Pitch</h4>
                        </div>
                        <div class="col-6">
                            <form @submit.prevent="addpitchExtrainfo" class="d-flex align-items-center justify-content-end">
                                <select class="form-control mr-2" v-model="activeextraInfo" required style="margin-right: 0.5rem !important;">
                                    <option :value="null">Select Extra Info</option>
                                    <option :value="iExtra" v-for="iExtra in extraInfo" :key="iExtra">{{ iExtra.name }}</option>
                                </select>
                                <button type="submit" class="btn bg-gradient-success mb-0">Add</button>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-6" v-for="(extraI, index) in data.data.extraInfo" :key="extraI">
                            <label>{{ extraI.name }}</label>
                            <div class="row">
                                <div class="col-8">
                                    <input class="form-control" type="text" v-model="data.data.extraInfo[index].data">
                                </div>
                                <div class="col-4">
                                    <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;" @click="deletepitchExtra(index)"><i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <light-editor v-model="data.data.text" />
                </div>
                <div class="modal-footer">
                    <button @click="modalclose('closeModal')" type="button" class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>
import { LightEditor } from "@hannanmiah/light-editor"

import "@hannanmiah/light-editor/dist/style.css"

export default {
    props: ["index", "active", "data"],
    data() {
        return {
            activepitchExtra: null,
            pitchExtra: [{
                id: "pitchBehaviour",
                name: "Pitch Behaviour"
            }, {
                id: "avgScore",
                name: "Avg Score"
            }, {
                id: "weather",
                name: "Weather"
            }, {
                id: "temperature",
                name: "Temperature"
            }, {
                id: "paceBowling",
                name: "Pace Bowling"
            }, {
                id: "spinBowling",
                name: "Spin Bowling"
            },
            ],
            activeextraInfo: null,
            extraInfo: [
                { name: "Batting Pitch" },
                { name: "Balanced Pitch" },
                { name: "Bowling Pitch" },
            ],
        }
    },
    async created() {
        // this.data.data.pitchExtra = this.pitchExtra;
    },
    components: { LightEditor },
    methods: {
        addpitchExtrainfo() {
            this.data.data.extraInfo.push(this.activeextraInfo);
        },
        deletepitchExtra(index) {
            this.data.data.pitchExtra.splice(index, 1);
        },
        addpitchExtra() {
            this.data.data.pitchExtra.push(this.activepitchExtra);
        },
        deletepitchExtra(index) {
            this.data.data.pitchExtra.splice(index, 1);
        },
        modalclose(funtext) {
            this.$emit(funtext, this.data, this.index);
        },
        async formSubmit() {
            this.modalclose("closeModal");
        },
    },
}
</script>

<style>
</style>