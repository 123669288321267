<template>
  <div class="card">
    <div class="card-body p-3" v-html="data"></div>
  </div>
</template>

<script>
export default {
  props: ["data"],
}
</script>

<style>
</style>