import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/dist/sweetalert2.min.css'

export default {
    swlloading() {
        Swal.close()
        Swal.showLoading()
    },

    swlerror(msg) {
        Swal.close()
        Swal.fire({
            icon: 'error',
            title: msg,
            showConfirmButton: true,
        })
    },

    swldone(msg) {
        Swal.close()
        Swal.fire({
            icon: 'success',
            title: msg,
            showConfirmButton: false,
            timer: 1200
        })
    },

    swlclose() {
        Swal.close()
    },

}