   
<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl"
    id="navbarBlur"
    navbar-scroll="true"
    v-bind:class="[navfix ? 'shadow-blur  position-sticky blur  mt-4 left-auto top-1 z-index-sticky' : 'shadow-none']"
  >
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
          <li class="breadcrumb-item text-sm">
            <a class="opacity-5 text-dark" href="javascript:;">Pages</a>
          </li>
          <li
            v-for="item in this.$route.path.substring(1).split('/')"
            :key="item"
            class="breadcrumb-item text-sm text-dark"
            aria-current="page"
          >{{ item }}</li>
        </ol>
        <h6 class="font-weight-bolder mb-0">{{ this.$route.name }}</h6>
      </nav>
      <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
        <div class="ms-md-auto pe-md-3 d-flex align-items-center"></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none d-flex align-items-center">
            <a
              href="javascript:;"
              class="nav-link text-body p-0"
              id="iconNavbarSidenav"
              @click="showhidesidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <li class="nav-item d-flex ps-3 align-items-center">
            <a
              style="cursor: pointer;"
              @click="logout"
              class="nav-link text-body font-weight-bold px-0"
            >
              <i class="fa fa-sign-out me-sm-1"></i>
              <span class="d-sm-inline d-none">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from "firebase";

import SwlAlerts from "../swlalerts.js";

export default {
  data() {
    return {
      patharray: [],
      navfix : false
    };
  },
  mounted: function () {
    this.patharray = this.$route.path.substring(1).split("/");
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          SwlAlerts.swldone("Successfully logged out");
          this.$router.push("/Login");
        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });
    },
    showhidesidebar() {
      this.$emit("toggelnav");
    },
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      
      if(currentScrollPosition > 50){
        this.navfix = true
      }else{
        this.navfix = false
      }
    },
  },
};
</script>

<style>
</style>