<template>
  <div class="d-flex flex-row bd-highlight mb-3">
    <div class="p-2 bd-highlight w-100">
      <div class="row align-items-center" style="min-height: 70px">
        <div class="col">
          <h4>Upcoming Matches</h4>
        </div>
      </div>
      <div
        class="mb-4"
        v-for="(match, index) in matches"
        :key="(match, index)"
        style="min-height:405px;"
      >
        <MatchCard
          :match="match"
          :index="index"
          :platfroms="sport.platforms"
          :action="true"
          @syncmatch="syncmatch"
          @deleterow="deleterow"
          @deletematch="deletematch"
        />
      </div>
    </div>

    <div class="p-2 bd-highlight w-100" v-if="platfroms.myteam11.status">
      <div class="row align-items-center" style="min-height: 70px">
        <div class="col">
          <h4>MyTeam11 Matches</h4>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-success w-100 mb-0"
            @click="addblankmt11()"
          >Blank Add</button>
        </div>
      </div>
      <draggable
        v-model="mt11matches"
        group="myteam11"
        :key="mt11matches"
        @start="drag=true"
        @end="drag=false"
        item-key="id"
      >
        <template #item="{element, index}">
          <div class="mb-4" style="min-height:405px;">
            <MT11MatchCard
              :match="element"
              :index="index"
              :action="true"
              @deletemteam11match="deletemteam11match"
            />
          </div>
        </template>
      </draggable>
    </div>

    <div class="p-2 bd-highlight w-100" v-if="platfroms.my11circle.status">
      <div class="row align-items-center" style="min-height: 70px">
        <div class="col">
          <h4>My11Circle Matches</h4>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-success w-100 mb-0"
            @click="addblankm11c()"
          >Blank Add</button>
        </div>
      </div>
      <draggable
        v-model="my11cmatches"
        group="my11cricle"
        :key="my11cmatches"
        @start="drag=true"
        @end="drag=false"
        item-key="id"
      >
        <template #item="{element, index}">
          <div class="mb-4" style="min-height:405px;">
            <MY11CMatchCard
              :match="element"
              :index="index"
              :action="true"
              @swapmy11cteam="swapmy11cteam"
              @deletemy11criclematch="deletemy11criclematch"
            />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import draggable from "vuedraggable";
import database from "../../databasefuncation";

import MatchCard from "./platfrom/match-card.vue";
import MT11MatchCard from "./platfrom/match-card-mt11.vue";
import MY11CMatchCard from "./platfrom/match-card-my11c.vue";

export default {
  props: ["sport"],
  components: { MatchCard, MT11MatchCard, MY11CMatchCard, draggable },

  data() {
    return {
      matches: [],
      mt11matches: [],
      my11cmatches: [],
      platfroms: {},
      dsport: {},
    };
  },
  async created() {
    const db = firebase.firestore();

    this.sport.platforms.forEach((platfrom) => {
      this.platfroms[platfrom.id] = platfrom;
    });

    var currentdate = firebase.firestore.Timestamp.now().toDate();

    var path = db
      .collection("matches")
      .where("sport", "==", this.sport.id)
      .where("time", ">", currentdate)
      .orderBy("time");

    this.matches = await database.getalldocs(path);

    if (this.platfroms.myteam11.status) {
      var teampmatches;

      var hostname =
        location.hostname === "localhost"
          ? "https://proapi2.teammaster.in"
          : "https://proapi2.teammaster.in";

      await this.axios
        .get(
          `${hostname}/myteam11-matches?sport=${this.platfroms.myteam11.sportid}`
        )
        .then((response) => {
          
          teampmatches = response["data"]["Response"]["NotStarted"];

          console.log(teampmatches);

          teampmatches.forEach((match) => {
            match.StartDate = this.dateformater(match.StartDate);

            this.mt11matches.push(match);
          });

          this.mt11matches.sort(function (a, b) {
            return a.StartDate - b.StartDate;
          });

        });
      
    }

    if (this.platfroms.my11circle.status) {
      var hostname =
        location.hostname === "localhost"
          ? "https://proapi2.teammaster.in"
          : "https://proapi2.teammaster.in";

      await this.axios
        .get(
          `${hostname}/my11circle-matches?sport=${this.platfroms.my11circle.sportid}`
        )
        .then((response) => {
          response.data.forEach((match) => {
            if (match.addInfo.infoType != null && match.addInfo.infoType != undefined) {
              this.my11cmatches.push(match);
            }
          });
        });

      this.my11cmatches.sort(function (a, b) {
        return a.matchFreezeTime - b.matchFreezeTime;
      });
    }
  },
  methods: {
    async syncmatch(index) {
      const match = this.matches[index];

      const matchid = match.id;
      const seriesid = match.series.id;
      const team1id = match.teams[0].id;
      const team2id = match.teams[1].id;

      if (this.platfroms.myteam11.status) {
        const mt11match = this.mt11matches[index];

        if (mt11match.empty != true) {
          var mt11seriesid = null;
          var mt11matchid = mt11match.MatchId;
          var mt11team1id = null;
          var mt11team2id = null;

          await this.updatedata(
            this.platfroms.myteam11.id,
            matchid,
            seriesid,
            team1id,
            team2id,
            match.teams,
            mt11matchid,
            mt11seriesid,
            mt11team1id,
            mt11team2id,
            index
          );
        }
      }

      if (this.platfroms.my11circle.status) {
        const m11cmatch = this.my11cmatches[index];

        if (m11cmatch.empty != true) {
          var m11cseriesid = m11cmatch.seriesId;
          var m11cmatchid = m11cmatch.matchId;
          var m11cteam1id = m11cmatch.team1.id;
          var m11cteam2id = m11cmatch.team2.id;

          await this.updatedata(
            this.platfroms.my11circle.id,
            matchid,
            seriesid,
            team1id,
            team2id,
            match.teams,
            m11cmatchid,
            m11cseriesid,
            m11cteam1id,
            m11cteam2id,
            index
          );
        }
      }
    },

    async updatedata(
      platfrom,
      matchid,
      seriesid,
      team1id,
      team2id,
      teamsdata,
      platformmatchid,
      platfromseriesid,
      platfromteam1id,
      platfromteam2id,
      index
    ) {

      const db = firebase.firestore();

      teamsdata[0].platforms[platfrom] = { id: platfromteam1id };
      teamsdata[1].platforms[platfrom] = { id: platfromteam2id };

      await database.updatedoc(
        { ["platforms." + platfrom + ".id"]: platfromseriesid },
        "series",
        seriesid.toString()
      );

      await database.updatedoc(
        { ["platforms." + platfrom + ".id"]: platfromteam1id },
        "teams",
        team1id.toString()
      );

      await database.updatedoc(
        { ["platforms." + platfrom + ".id"]: platfromteam2id },
        "teams",
        team2id.toString()
      );

      await database.updatedoc(
        {
          ["platforms." + platfrom + ".id"]: platformmatchid,
          ["series.platforms." + platfrom + ".id"]: platfromseriesid,
          teams: teamsdata,
        },
        "matches",
        matchid.toString()
      );

      var path = db.collection("matches").doc(matchid.toString());

      this.matches[index] = await database.getdoc(path);
    },

    deleterow(index) {
      this.deletematch(index);
      this.deletemteam11match(index);
      this.deletemy11criclematch(index);
    },

    deletematch(index) {
      this.matches.splice(index, 1);
    },

    deletemteam11match(index) {
      this.mt11matches.splice(index, 1);
    },

    deletemy11criclematch(index) {
      this.my11cmatches.splice(index, 1);
    },

    swapmy11cteam(index) {
      var teamone = this.my11cmatches[index].team1;
      var teamtwo = this.my11cmatches[index].team2;

      this.my11cmatches[index].team1 = teamtwo;
      this.my11cmatches[index].team2 = teamone;
    },

    addblankmt11() {
      this.mt11matches.unshift({
        empty: true,
      });
    },
    addblankm11c() {
      this.my11cmatches.unshift({
        empty: true,
      });
    },

    dateformater(datedate) {
      var accetdate =
        datedate.split(" ")[0].split("-")[1] +
        "-" +
        datedate.split(" ")[0].split("-")[0] +
        "-" +
        datedate.split(" ")[0].split("-")[2] +
        " " +
        datedate.split(" ")[1];

      accetdate = new Date(accetdate);

      return accetdate.getTime();
    },
  },
};
</script>

<style>
</style>