<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-sm-12 align-left">
          <p class="m-0">Name</p>
          <h5>
            <b>{{ name }}</b>
          </h5>
        </div>

        <div class="col-sm-4 align-left">
          <p class="m-0">ID</p>
          <h5>
            <b>{{ id }}</b>
          </h5>
        </div>
        <div class="col-sm-4 align-left">
          <p class="m-0">D11 ID</p>
          <h5>
            <b>{{ did }}</b>
          </h5>
        </div>
        <div class="col-sm-4 align-left">
          <p class="m-0">Sport</p>
          <h5>
            <b>{{ sport }}</b>
          </h5>
        </div>
      </div>
      <div v-if="action" class="row">
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-secondary w-100 mb-0"
            @click="editseries"
          >Edit</button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-warning w-100 mb-0"
            @click="deleteseries"
          >Delete</button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-danger w-100 mb-0"
            @click="comdeleteseries"
          >Clear Data</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  props: ["did", "id", "name", "sport", "teams", "action"],
  data() {
    return {};
  },
  methods: {
    async deleteseries() {
      await database.deletedoc("series", this.id.toString());
    },

    editseries() {
      console.log("Push");
      this.$router.push({ path: `/series/edit-series/${this.id}` });
    },
    async comdeleteseries() {
      const db = firebase.firestore();

      

      var matchesPath = db
        .collection("matches")
        .where("series.id", "==", this.id);

      var allMatches = await database.getalldocs(matchesPath);

      

      for (const match of allMatches) {
        await database.deletedoc("matches", match.id.toString());
        await database.deletedoc("matches_data", match.id.toString());
      }

      var teamsPath = db
        .collection("teams")
        .where("seriess", "array-contains", {
          id: this.id,
          name: this.name,
        });

      var allTeams = await database.getalldocs(teamsPath);

      var allPlayers = [];

      for (const team of allTeams) { 

        var playersPath = db
          .collection("players")
          .where("teams", "array-contains", {
            id: team.id,
            sortname: team.sortname,
          });

        var tempallPlayers = await database.getalldocs(playersPath);

        allPlayers = allPlayers.concat(tempallPlayers);

        // await database.deletedoc("teams", team.id.toString());
      }

      for (const player of allPlayers) {
        await database.deletedoc("players", player.id.toString());
      }
      // await database.deletedoc("series", this.id.toString());
    },
  },
};
</script>
