<template>
  <div class="row">
    <div class="col-xl-4 mb-4" v-for="banner in banners" :key="banner">
      <BannerPreview
        :id="banner.id"
        :imageurl="banner.imageurl"
        :active="banner.active"
        :data="banner.data"
        :position="banner.position"
        :action="action"
      />
    </div>
  </div>
</template>

<script>
import BannerPreview from "../../components/banners/Banner-Preview.vue";

import firebase from "firebase";

export default {
  components: { BannerPreview },
  data() {
    return {
      banners: [],
      action: true,
    };
  },
  created() {
    const db = firebase.firestore();
    db.collection("banners")
      .onSnapshot((querySnapshot) => {
        var bannerstemp = [];
        querySnapshot.forEach((doc) => {
          bannerstemp.push(doc.data());
        });
        this.banners = bannerstemp;
      });
  },
};
</script>

<style>
</style>