<template>
    <div class="row">
        <div class="col d-flex align-items-center">
            <p class="h3">Others</p>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
            <!-- <button @click="addotherprediction" type="button" class="btn bg-gradient-success btn-sm mb-0">Add Other Prediction</button> -->
        </div>
    </div>
    <div class="otherPredition">
        <div class="card mb-3" v-for="(other, index) in others" :key="other">
            <div class="card-body p-3 d-flex align-items-center">
                <div class="avatar avatar-xl me-3" style="position: relative;">
                    <img :src="other.image" alt="kal" class="border-radius-lg shadow" />
                    <span v-if="other.isVideo" class="videoBatch"><i class="fas fa-video me-2"></i></span>
                </div>
                <div class="d-flex align-items-start flex-column justify-content-center">
                    <h6 class="mb-1 text-sm">{{ other.title }}</h6>
                    <p class="mb-0 text-xs"><a href=""> {{ other.name }} </a></p>
                </div>
                <div class="ms-auto text-end">
                    <a class="btn btn-link text-dark px-3 mb-0" :href="other.url" target="_blank"><i class="fas fa-eye text-dark me-2" aria-hidden="true"></i>View</a>
                    <a @click="deleteOtherPrediction(index)" class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;"><i class="far fa-trash-alt me-2"
                            aria-hidden="true"></i>Delete</a>
                </div>

            </div>
        </div>
    </div>
    <div class="addOtherPredition">
        <div class="card">
            <div class="card-body">
                <form @submit.prevent="sendaddotherprediction">
                    <div class="row">
                        <div class="col-12">
                            <label>Title</label>
                            <div class="mb-3">
                                <input type="text" class="form-control" placeholder="Prediction Title" v-model="title" required />
                            </div>
                        </div>
                        <div class="col-12">
                            <label>URL</label>
                            <div class="mb-3">
                                <input type="text" class="form-control" placeholder="Prediction URL" v-model="url" required />
                            </div>
                        </div>
                        <div class="col-4">
                            <label>Select Compnay</label>
                            <div class="mb-3">
                                <select class="form-control" v-model="activeotherCompnay">
                                    <option :value="null">Select Company</option>
                                    <option :value="otherCompnay" v-for="otherCompnay in otherpredictionCompany" :key="otherCompnay">{{ otherCompnay.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4">
                            <label>Is Video</label>
                            <div class="mb-3">
                                <div class="form-check form-switch ps-0">
                                    <input v-model="isVideo" class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault" checked="">
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <label> </label>
                            <div class="mb-3">
                                <button type="submit" class="btn bg-gradient-success w-100 mb-0">Add Other Compnay Now</button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
    props: ["others"],
    data() {
        return {
            otherpredictionCompany: [],
            activeotherCompnay: null,
            isVideo: false,
            title: "",
            url: null,
        };
    },
    async created() {
        const db = firebase.firestore();
        var perditinoPath = db.collection("manage_app").doc("predictionData");
        var tempdata = await database.getdoc(perditinoPath);
        this.otherpredictionCompany = tempdata.otherPrediction;
    },
    methods: {
        deleteOtherPrediction(index) {
            this.$emit("deleteOtherPrediction", index);
        },
        sendaddotherprediction() {
            this.$emit("addotherprediction", {
                title: this.title,
                url: this.url,
                isVideo: this.isVideo,
                image: this.activeotherCompnay.image,
                name: this.activeotherCompnay.name,
            });
        }
    }
}
</script>

<style scoped>
    .videoBatch i{
        position: absolute;
        padding: 5px;
        font-size: 16px;
        top: -5px;
        left: -5px;
        border-radius: 50%;
        background-color: #FF0000;
    }
</style>