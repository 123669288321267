<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }" :id="add_other_prediction" tabindex="-1" role="dialog" :aria-labelledby="add_other_prediction"
        aria-hidden="true">
        <div v-if="active" class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">{{ data.name }}</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <light-editor v-model="data.data" />
                </div>
                <div class="modal-footer">
                    <button @click="modalclose('closeModal')" type="button" class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>
import { LightEditor, LightEditorPlugin } from "@hannanmiah/light-editor"

import "@hannanmiah/light-editor/dist/style.css"

export default {
    props: ["index", "active", "data"],
    components: { LightEditor },
    methods: {
        modalclose(funtext) {
            this.$emit(funtext, this.data, this.index);
        },
        async formSubmit() {
            this.modalclose("closeModal");
        },
    },
}
</script>

<style>
</style>