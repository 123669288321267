<template>
  <div class="row">
    <div class="col-xl-4 mb-4" v-for="player in players" :key="player">
      <PlayerPreview
        :credits="player.credits"
        :did="player.did"
        :id="player.id"
        :img="player.img"
        :name="player.name"
        :sport="player.sport"
        :type="player.type"
        :action="action"
        :teams="player.teams"
      />
    </div>
  </div>
</template>

<script>
import PlayerPreview from "../../components/players/Player-Preview.vue";

import firebase from "firebase";

export default {
  components: { PlayerPreview },
  data() {
    return {
      players: [],
      action: true,
    };
  },
  created() {
    const db = firebase.firestore();
    db.collection("players")
      .orderBy("name")
      .onSnapshot((querySnapshot) => {
        var playerstemp = [];
        querySnapshot.forEach((doc) => {
          playerstemp.push(doc.data());
        });
        this.players = playerstemp;
      });
  },
};
</script>

<style>
</style>