<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }" :id="add_other_prediction" tabindex="-1" role="dialog" :aria-labelledby="add_other_prediction"
        aria-hidden="true">
        <div v-if="active" class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">{{ data.name }}</h6>
                    <button type="button" class="btn  bg-gradient-success mb-0" @click="addData()">Add</button>
                </div>
                <div class="modal-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <td>
                                    <h6 class="mb-0 text-sm">Inning Name</h6>
                                </td>
                                <td>
                                    <h6 class="mb-0 text-sm">Pacers</h6>
                                </td>
                                <td>
                                    <h6 class="mb-0 text-sm">Spinners</h6>
                                </td>
                                <td>
                                    <h6 class="mb-0 text-sm">Action</h6>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(rowdata, index) in data.data" :key="rowdata">
                                <td><input v-model="rowdata.name" type="text" class="form-control"></td>
                                <td><input v-model="rowdata.pacers" type="text" class="form-control"></td>
                                <td><input v-model="rowdata.spinners" type="text" class="form-control"></td>
                                <td><a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;" @click="deleteData(index)"><i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button @click="modalclose('closeModal')" type="button" class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>

export default {
    props: ["index", "active", "data"],
    methods: {
        addData() {
            this.data.data.push({
                name: "Inning",
                pacers: "0 W",
                spinners: "0 W"
            });
        },
        deleteData(index) {
            this.data.data.splice(index, 1);
        },
        modalclose(funtext) {
            this.$emit(funtext, this.data, this.index);
        },
        async formSubmit() {
            this.modalclose("closeModal");
        },
    },
}
</script>

<style>
</style>