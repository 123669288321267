<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <label>Select Sport</label>
          <div class="mb-3">
            <select class="form-control" v-model="activeSport" @change="sportSelected">
              <option value="">Select Sport</option>
              <option :value="sport.id" v-for="sport in sports" :key="sport">{{ sport.name }}</option>
            </select>
          </div>
        </div>
        <div class="col-4">
          <label>Select Match Type</label>
          <div class="mb-3">
            <select class="form-control" v-model="mstchType" @change="matchTypeSelected">
              <option value="">Select Match Type</option>
              <option value="upcoming">Upcoming</option>
              <option value="completed">Completed</option>
            </select>
          </div>
        </div>
        <div class="col-4">
          <label>Select Match</label>
          <div class="mb-3">
            <select class="form-control" v-model="activeMatch" @change="matchSelected">
              <option value="">Select Match</option>
              <option :value="match" v-for="match in matches" :key="match">{{ match.teams[0].name }} vs {{ match.teams[1].name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="h3">Match Info</p>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <label>Time</label>
              <div class="mb-3">
                <input type="datetime-local" class="form-control" placeholder="Match Time" v-model="localtime" required />
              </div>
            </div>
            <div class="col-2">
              <label>Sport</label>
              <div class="mb-3">
                <input type="text" class="form-control" placeholder="Sport" v-model="prediction.sport" required />
              </div>
            </div>

            <div class="col-2">
              <label>Location</label>
              <div class="mb-3">
                <input type="text" class="form-control" placeholder="Location" v-model="prediction.location" required />
              </div>
            </div>
            <div class="col-3">
              <label>Stadium</label>
              <div class="mb-3">
                <input type="text" class="form-control" placeholder="Stadium" v-model="prediction.stadium" required />
              </div>
            </div>
            <div class="col-2">
              <label>Notification</label>
              <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="prediction.notification" />
                  </div>
                </div>
            </div>
          </div>
          <div class="row" v-for="(team, index) in prediction.teams" :key="team">
            <div class="col-4">
              <label>Team {{ index + 1 }} Name</label>
              <div class="mb-3">
                <input type="text" class="form-control" placeholder="Team Name" v-model="team.name" required />
              </div>
            </div>
            <div class="col-4">
              <label>Team {{ index + 1 }} Sort Name</label>
              <div class="mb-3">
                <input type="text" class="form-control" placeholder="Team Sort Name" v-model="team.sortname" required />
              </div>
            </div>
            <div class="col-4">
              <label>Team {{ index + 1 }} Color</label>
              <div class="mb-3">
                <input style="height : 40px" type="color" class="form-control" placeholder="Team Color" v-model="team.color" required />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <AddDefaultPrediction :defaults="prediction.defaults" :players="players" :sport="activeSport" :teams="prediction.teams" @printdefaultprediction="printdefaultprediction" />
    </div>
    <div class="col-6">
      <AddOtherPrediction :others="prediction.others" @addotherprediction="addotherprediction" @deleteOtherPrediction="deleteOtherPrediction" />
    </div>
    <div class="col-12 mt-4">
      <button type="button" @click="savePrediction()" class="btn bg-gradient-info mb-0">
        <span v-if="isnew">Save Prediction</span>
        <span v-else>Update Prediction</span>
      </button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import TimeRight from "../../timeright";
// import AddOtherPredictionModal from "./modals/add_other_prediction_modal.vue"
import AddOtherPrediction from "./otherPrediction.vue"
import AddDefaultPrediction from "./defaultPrediction.vue"

export default {
  components: { AddOtherPrediction, AddDefaultPrediction },
  props: ["isnew"],

  data() {
    return {
      sports: [],
      activeSport: "",
      mstchType: "upcoming",
      matches: [],
      activeMatch: {},
      activeMatchData: {},
      players: [],
      localtime : null,
      prediction: {
        id: null,
        time: null,
        sport: null,
        notification : false,
        location: null,
        stadium: null,
        teams: [],
        defaults: [],
        others: [],
      },
    };
  },
  async created() {

    const db = firebase.firestore();


    var path = db.collection("sports").orderBy("position");
    this.sports = await database.getalldocs(path);

    if (this.isnew) {

    } else {
      this.prediction.id = this.$route.params.id;

      var path = db.collection("prediction").doc(this.prediction.id.toString());
      this.prediction = await database.getdoc(path);

      this.activeSport = this.prediction.sport;

      await this.sportSelected();

      this.matches.forEach(match => {

        if (match.id == this.prediction.id) {
          this.activeMatch = match;
        }

      });


      
      this.localtime = TimeRight.getlocaldattime(this.prediction.time);

      await this.getPlayers();
    }


  },
  methods: {

    addotherprediction(data) {
      this.prediction.others.push(data);
    },

    printdefaultprediction() {
      
    },

    deleteOtherPrediction(index) {
      this.prediction.others.splice(index, 1);
    },

    async savePrediction() {
  
      this.prediction.time = new Date(this.localtime);

      console.log(this.prediction.id);
      
      await database.savedoc(this.prediction, "prediction", this.prediction.id.toString());

      await database.updatedoc({ prediction: true }, "matches", this.prediction.id.toString());

    },

    async sportSelected() {
      await this.matchTypeSelected();
    },
    async matchTypeSelected() {
      const db = firebase.firestore();
      var currentdate = firebase.firestore.Timestamp.now().toDate();
      var path = this.mstchType == "upcoming"
        ? db
          .collection("matches")
          .where("sport", "==", this.activeSport)
          .where("time", ">", currentdate)
          .orderBy("time")
        : db
          .collection("matches")
          .where("sport", "==", this.activeSport)
          .where("time", "<", currentdate)
          .orderBy("time")
      this.matches = await database.getalldocs(path);
    },

    async matchSelected() {

      this.prediction = {
        id: null,
        time: null,
        sport: null,
        notification : false,
        location: null,
        stadium: null,
        teams: [],
        defaults: [],
        others: [],
      };


      const db = firebase.firestore();

      var existPrediction = db.collection("prediction").doc(this.activeMatch.id.toString());
      var tempExistData = await database.getdoc(existPrediction);

      if (tempExistData != null) {
        this.prediction.id = tempExistData.id;
        this.localtime = TimeRight.getlocaldattime(tempExistData.time);
        this.prediction.sport = tempExistData.sport;
        this.prediction.location = tempExistData.location;
        this.prediction.stadium = tempExistData.stadium;
        this.prediction.teams = tempExistData.teams;
        this.prediction.defaults = tempExistData.defaults;
        this.prediction.others = tempExistData.others;
        this.prediction.notification = tempExistData.notification;
      } else {
        this.localtime = TimeRight.getlocaldattime(this.activeMatch.time);
        this.prediction.sport = this.activeMatch.sport;
        this.prediction.teams = this.activeMatch.teams;
        this.prediction.id = this.activeMatch.id;
        this.prediction.teams.forEach(team => {
          delete team.platforms;
          delete team.id;
          delete team.did;
        });
      }

      await this.getPlayers();


    },

    async getPlayers(){

      const db = firebase.firestore();

      var matchDatapath = db.collection("matches_data").doc(this.prediction.id.toString());

      this.players = await database.getdoc(matchDatapath);
      this.players = this.players.players;

    }



  },
};
</script>

<style scoped>
  .form-switch .form-check-input,
  .form-check .form-check-input {
    margin-left: 0 !important;
    float: none;
  }
  </style>  