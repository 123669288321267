<template>
  <div class="row">
    <div class="col-xl-8 mb-3">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="saveplayer">
            <div class="row">
              <div class="col-xl-4">
                <label>ID</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Player Id"
                    v-model.number="id"
                    v-bind:disabled="!isnew"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Dream11 ID</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Dream11 Player Id"
                    v-model.number="did"
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Name</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Player Name"
                    v-model="name"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Credits</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Player Credits"
                    v-model="credits"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Sport</label>
                <div class="mb-3">
                  <select class="form-control" @change="inserttypes" v-model="sport" required>
                    <option :value="sport.id" v-for="sport in sports" :key="sport">{{ sport.name }}</option>
                  </select>
                </div>
              </div>

              <div class="col-xl-4">
                <label>Type</label>
                <div class="mb-3">
                  <select class="form-control" v-model="type" required>
                    <option :value="type.sortname" v-for="type in types" :key="type">{{ type.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row" v-for="(team, index) in teams" :key="(team, index)">
              <div class="col-xl-6">
                <div class="mb-2">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="Team Id"
                    v-model.number="team.id"
                    required
                  />
                </div>
              </div>
              <div class="col-xl-6">
                <div class="mb-3">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="Team Sort Name"
                    v-model="team.sortname"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save Player</span>
                  <span v-else>Update Player</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <TeamList :sport="sport" :selectedteams="teams" @senddata="addteam($event)" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import SwlAlerts from "../../swlalerts.js";
import database from "../../databasefuncation";

import TeamList from "../../components/series/Team-List.vue";

export default {
  props: ["isnew"],
  components: { TeamList },
  data() {
    return {
      id: null,
      did: null,
      name: "",
      credits: null,
      sport: "",
      type: "",
      img: "",
      teams: [],
      platforms: {},
      sports: [],
      types: [],
      action: false,
    };
  },
  async created() {
    const db = firebase.firestore();

    if (this.isnew) {
      var path = db.collection("players").orderBy("id", "desc").limit(1);

      var alldocs = await database.getalldocs(path);

      if (alldocs.length == 0) {
        this.id = 0;
      } else {
        alldocs.forEach((doc) => {
          this.id = parseInt(doc.id) + 1;
        });
      }
    } else {
      this.id = this.$route.params.id;

      var path = db.collection("players").doc(this.id);

      var player = await database.getdoc(path);

      this.did = player.did;
      this.name = player.name;
      this.credits = player.credits;
      this.sport = player.sport;
      this.type = player.type;
      this.teams = player.teams;
      this.platforms = player.platforms;
    }

    var pathsport = db.collection("sports").orderBy("position");

    this.sports = await database.getalldocs(pathsport);

    this.sports.forEach((sport) => {
      if (!this.isnew) {
        if (sport.id == this.sport) {
          this.types = sport.types;
        }
      }
    });

    this.inserttypes();
  },
  methods: {
    inserttypes() {
      this.sports.forEach((sport) => {
        if (this.sport == sport.id) {
          this.types = sport.types;
        }
      });
    },

    addteam(data) {
      this.teams = data;
    },

    async saveplayer() {
      var object = this.isnew
        ? {
            id: this.id,
            did: this.did,
            name: this.name,
            credits: parseFloat(this.credits),
            sport: this.sport,
            type: this.type,
            img: this.img,
            teams: this.teams,
            platforms: {
              dream11: { id: this.did, credits: parseFloat(this.credits) },
            },
          }
        : {
            id: this.id,
            did: this.did,
            name: this.name,
            credits: parseFloat(this.credits),
            sport: this.sport,
            type: this.type,
            img: this.img,
            teams: this.teams,
            ["platforms.dream11.id"] : this.did,
            ["platforms.dream11.credits"] : parseFloat(this.credits),
          };

      this.isnew
        ? await database.savedoc(object, "players", this.id.toString())
        : await database.updatedoc(object, "players", this.id.toString());
    },
  },
};
</script>

<style>
</style>