<template>
  <div class="row">
    <div class="col-xl-12 mb-3">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="updatesubscription">
            <div class="row">
              <div class="col-xl-6">
                <label>Title</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="title" v-model="title" required />
                </div>
              </div>
              <div class="col-xl-6">
                <label>Description</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Description" v-model="description" required />
                </div>
              </div>

              <div class="col-xl-10">
                <h2>Features</h2>
              </div>

              <div class="col-xl-2" style="text-align: right;">
                <button type="button" class="btn bg-gradient-secondary w-100" @click="addfeature">Add Feature</button>
              </div>

              <div class="col-xl-12">
                <div class="row" v-for="(feature, index) in features" :key="(feature, index)">
                  <div class="col-xl-2">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Icon" v-model="feature.icon" required />
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Title" v-model="feature.title" required />
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Description" v-model="feature.description"
                        required />
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="mt-1 mb-3">
                      <div class="form-check form-switch">
                        <b>Hide </b>
                        <input class="form-check-input" type="checkbox" v-model="feature.hide" />
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <button type="button" class="btn bg-gradient-danger w-100"
                      @click="deletefeature(index)">Delete</button>
                  </div>
                </div>
              </div>

              <div class="col-xl-10">
                <h2>Plans</h2>
              </div>

              <div class="col-xl-2" style="text-align: right;">
                <button type="button" class="btn bg-gradient-secondary w-100" @click="addplan">Add Plan</button>
              </div>

              <div class="col-xl-12">
                <div class="row" v-for="(plan, index) in plans" :key="(plan, index)">
                  <div class="col-xl-1">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Id" v-model="plan.id" required />
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Name" v-model="plan.name" required />
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <div class="mb-3">
                      <input type="number" class="form-control" placeholder="Price" v-model.number="plan.price"
                        required />
                    </div>
                  </div>

                  <div class="col-xl-1">
                    <div class="mb-3">
                      <input type="number" class="form-control" placeholder="Day" v-model.number="plan.day" required />
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <div class="mt-1 mb-3">
                      <div class="form-check form-switch">
                        <b>Status</b>
                        <input class="form-check-input" type="checkbox" v-model="plan.status" />
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <div class="mt-1 mb-3">
                      <div class="form-check form-switch">
                        <b>Pro</b>
                        <input class="form-check-input" type="checkbox" v-model="plan.pro" />
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <div class="mt-1 mb-3">
                      <div class="form-check form-switch">
                        <b>Offer</b>
                        <input class="form-check-input" type="checkbox" v-model="plan.offer" />
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <button type="button" class="btn bg-gradient-danger w-100"
                      @click="deleteplan(index)">Delete</button>
                  </div>

                  <div class="col-xl-12" v-if="plan.offer">
                    <div class="row">
                      <div class="col-xl-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Offer Name" v-model="plan.offer_name"
                            required />
                        </div>
                      </div>

                      <div class="col-xl-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Offer Price"
                            v-model.number="plan.offer_price" required />
                        </div>
                      </div>

                      <div class="col-xl-3">
                        <div class="mb-3">
                          <input style="height: 40px;" type="color" class="form-control" placeholder="Offer Color 1"
                            v-model="plan.offer_color_1" required />
                        </div>
                      </div>

                      <div class="col-xl-3">
                        <div class="mb-3">
                          <input style="height: 40px;" type="color" class="form-control" placeholder="Offer Color 2"
                            v-model="plan.offer_color_2" required />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-2" style="text-align:right;">
                <button type="submit" class="btn bg-gradient-info mb-0 w-100">
                  <span>Update Subscription</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  data() {
    return {
      title: "",
      description: "",
      features: [],
      plans: [],
    };
  },

  async created() {
    var db = firebase.firestore();

    var path = db.collection("manage_app").doc("subscription");

    var subscription = await database.getdoc(path);

    this.title = subscription.title;
    this.description = subscription.description;
    this.features = subscription.features;
    this.plans = subscription.plans;
  },

  methods: {
    updatesubscription() {
      var object = {
        title: this.title,
        description: this.description,
        features: this.features,
        plans: this.plans,
      };

      database.updatedoc(object, "manage_app", "subscription");
    },

    addfeature() {
      this.features.push({
        icon: "",
        title: "",
        description: "",
        hide: false,
      });
    },
    deletefeature(index) {
      this.features.splice(index, 1);
    },
    addplan() {
      this.plans.push({
        id: "",
        name: "",
        price: null,
        day: null,
        status: true,
        offer: false,
        pro: false,
      });
    },
    deleteplan(index) {
      this.plans.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>