<template>
  <div class="row">
    <div
      class="col-xl-4 mb-4"
      v-for="NewsItem in News"
      :key="NewsItem.id"
    >
      <NewsPreview
        :id="NewsItem.id"
        :content="NewsItem.content"
        :description="NewsItem.description"
        :image="NewsItem.image"
        :publishedAt="NewsItem.publishedAt"
        :source="NewsItem.source"
        :time="NewsItem.time"
        :title="NewsItem.title"
        :url="NewsItem.url"
        :action="action"
      />
    </div>
    <div v-if="loading" class="loading-indicator">Loading...</div>
    <div ref="loadMore" class="load-more-trigger"></div>
  </div>
</template>

<script>
import NewsPreview from "../../components/news/News-Preview.vue";
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  components: { NewsPreview },
  data() {
    return {
      News: [],
      action: true,
      loading: false,
      lastDoc: null, // Tracks the last document for pagination
    };
  },
  async created() {
    await this.loadNews();
    this.setupIntersectionObserver();
  },
  methods: {
    async loadNews() {
      this.loading = true;
      const db = firebase.firestore();
      let query = db.collection("news").orderBy("time", "desc").limit(10);

      if (this.lastDoc) {
        query = query.startAfter(this.lastDoc);
      }

      const snapshot = await query.get();
      const newNews = snapshot.docs.map(doc => ({
        id: doc.id, // Include document ID here
        ...doc.data(),
      }));

      

      this.News.push(...newNews);

      if (snapshot.docs.length > 0) {
        this.lastDoc = snapshot.docs[snapshot.docs.length - 1];
      }

      this.loading = false;
    },
    setupIntersectionObserver() {
      const observer = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && !this.loading) {
          await this.loadNews();
        }
      }, { threshold: 1.0 });

      observer.observe(this.$refs.loadMore);
    },
  },
};
</script>

<style>
.loading-indicator {
  text-align: center;
  font-size: 1.2rem;
}
.load-more-trigger {
  height: 1px;
}
</style>
