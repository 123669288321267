<template>
  <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"
    :id="add_subscription" tabindex="-1" role="dialog" :aria-labelledby="add_subscription" aria-hidden="true">
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="modal-title-default">Add New Subscription</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addsubscription">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Order Id" v-model="subscription.orderId" required />
            </div>

            <div class="form-group">
              <input type="text" class="form-control" placeholder="Day" v-model.number="subscription.day" required />
            </div>


            <div class="form-group">
              <div class="form-check form-switch">
                <b>Pro</b>
                <input class="form-check-input" type="checkbox" v-model="subscription.pro" />
              </div>
            </div>



            <button type="submit" class="btn bg-gradient-success w-100 mb-0">Add Subscription Now</button>
          </form>
        </div>
        <div class="modal-footer">
          <button @click="modalclose('addsubscription')" type="button" class="btn btn-link ml-auto mb-0"
            data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import TimeRight from "../../timeright";

export default {
  props: ["user", "active"],
  data() {
    return {
      subscription: {
        orderId: "",
        day: 10,
        pro: false,
      },
    };
  },
  created() {
    this.subscription.orderId = this.makeid(20);
  },
  methods: {
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    modalclose(funtext) {
      this.$emit(funtext);
    },
    async addsubscription() {

      var db = firebase.firestore();

      var path = db.collection("subscriptions");

      var startDate = new Date();
      var endDate = new Date();

      endDate.setDate(endDate.getDate() + this.subscription.day);

      var object = {
        bankName: "",
        bankTxnId: "",
        day: this.subscription.day,
        pro: this.subscription.pro,
        displayName: this.user.displayName,
        email: this.user.email,
        endDate: endDate,
        gatewayName: "",
        offer: false,
        offeramount: "",
        offername: "",
        orderId: this.subscription.orderId,
        paymentMode: "",
        planid: "adminactive",
        planname: "Admin Active",
        price: 0,
        refundAmt: 0,
        startDate: startDate,
        state: this.user.state,
        txnAmount: 0,
        txnDate: startDate,
        txnId: "",
        txnType: "",
        uid: this.user.uid,
      };

      await database.savedoc(object, "subscriptions", this.subscription.orderId);

      await this.axios
        .post(`https://asia-south1-tmpfbp.cloudfunctions.net/menualsubscribe`, { uid: this.user.uid, })
        .then((response) => {
          console.log(response);
          swlalerts.swlclose();
        });

      this.modalclose("addsubscription");


    },
  },
};
</script>

<style></style>