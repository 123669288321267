<template>
  <div
    :class="{'modal fade' : true, show: active}"
    :style="{'display': active ? 'block' : 'none'}"
    :id="add_subscription"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="add_subscription"
    aria-hidden="true"
  >
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="modal-title-default">Check Subscription</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addsubscription">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Order Id"
                v-model="orderId"
                required
              />
            </div>

            <div class="form-group">
              <select class="form-control" v-model="planid" required>
                <option value="free">Free</option>
                <option value="15_day">Paid</option>
              </select>
            </div>

            <div class="form-group">
              <input type="text" class="form-control" placeholder="User UID" v-model="uid" required />
            </div>

            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="User Name"
                v-model="displayName"
                required
              />
            </div>

            <div class="form-group">
              <input
                type="email"
                class="form-control"
                placeholder="User Email"
                v-model="email"
                required
              />
            </div>

            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="User State"
                v-model="state"
                required
              />
            </div>

            <button type="submit" class="btn bg-gradient-success w-100 mb-0">Check Subscription</button>
          </form>
        </div>
        <div class="modal-footer">
          <button
            @click="modalclose('checksubscription')"
            type="button"
            class="btn btn-link ml-auto mb-0"
            data-bs-dismiss="modal"
          >Close</button>
        </div>
      </div>
    </div>
  </div>
  <div
    :class="{'modal-backdrop fade' : true, show: active}"
    :style="{'display': active ? 'block' : 'none'}"
  ></div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import swlalerts from "../../swlalerts";

export default {
  props: ["user", "active"],
  data() {
    return {
      orderId: null,
      planid: "15_day",
      uid: "",
      displayName: "",
      email: "",
      state: "",
    };
  },
  created() {
    this.uid = this.user.uid;
    this.displayName = this.user.displayName;
    this.email = this.user.email;
    this.state = this.user.state;
  },
  methods: {
    modalclose(funtext) {
      this.$emit(funtext);
    },
    async addsubscription() {
      const body = {
        orderId: this.orderId,
        planid: this.planid,
        uid: this.uid,
        displayName: this.displayName,
        email: this.email,
        state: this.state,
      };

      swlalerts.swlloading();

      await this.axios
        .post(`https://asia-south1-tmpfbp.cloudfunctions.net/paytmcheck`, body)
        .then((response) => {
          swlalerts.swlclose();
          if(response.data.body.resultInfo.resultCode == "01"){
            swlalerts.swldone(response.data.body.resultInfo.resultMsg);
            this.modalclose("checksubscription");
          }else{
            swlalerts.swlerror(response.data.body.resultInfo.resultMsg);
            this.modalclose("checksubscription");
          }
        });

        await this.axios
        .post(`https://asia-south1-tmpfbp.cloudfunctions.net/menualsubscribe`, {uid: this.uid,})
        .then((response) => {
          console.log(response);
          swlalerts.swlclose();
        });


    },
  },
};
</script>

<style>
</style>