<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-4 align-left">
          <p class="m-0">ID</p>
          <h5>
            <b>{{ id }}</b>
          </h5>
        </div>
        <div class="col-sm-4" style="text-align : left">
          <p class="m-0">Name</p>
          <h5>
            <b>{{ name }}</b>
          </h5>
        </div>
        <div class="col-sm-4" style="text-align : left">
          <p class="m-0">Status</p>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="status"
              style="margin-left: auto; margin-right: 0; float: none; "
              v-on:input="changesportsstate()"
              v-bind:disabled="!action"
            />
          </div>
        </div>

        <div class="col-sm-2 align-left">
          <p class="m-0">Pos..</p>
          <h5>
            <b>{{ position }}</b>
          </h5>
        </div>

        <div class="col-sm-2 align-left">
          <p class="m-0">Total</p>
          <h5>
            <b>{{ total }}</b>
          </h5>
        </div>

        <div class="col-sm-2" style="text-align : left">
          <p class="m-0">Max</p>
          <h5>
            <b>{{ max }}</b>
          </h5>
        </div>

        <div class="col-sm-2" style="text-align : left">
          <p class="m-0">Min</p>
          <h5>
            <b>{{ min }}</b>
          </h5>
        </div>

        <div class="col-sm-2" style="text-align : left">
          <p class="m-0">CC</p>
          <h5>
            <b>{{ cc }}</b>
          </h5>
        </div>

        <div class="col-sm-2" style="text-align : left">
          <p class="m-0">VC</p>
          <h5>
            <b>{{ vc }}</b>
          </h5>
        </div>

        <div class="col-sm-12 mt-3">
          <span
            v-for="platform in platforms"
            :key="platform"
            class="badge badge-secondary"
            style="margin-right:5px"
          >{{platform.name}}</span>
        </div>
      </div>

      <div
        class="preview"
        :style="{   backgroundImage: `url(${backgroundUrl})`, backgroundColor : `${color}` }"
        v-bind:class="{ action: action }"
      >
        <div class="row mt-3">
          <div class="col-sm-3" style="text-align : center" v-for="type in types" :key="type">
            <h5>
              <b>{{ type.sortname }} - {{ type.min }} : {{ type.max }}</b>
            </h5>
          </div>
        </div>
      </div>

      <div class="actionbtn" v-if="action">
        <div class="row">
          <div class="col-sm-6">
            <button type="button" class="btn bg-gradient-secondary w-100" @click="editsport">Edit</button>
          </div>
          <div class="col-sm-6">
            <button type="button" class="btn bg-gradient-danger w-100" @click="deletesport">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backgroundUrl from "../../assets/img/previewbg.png";
import database from "../../databasefuncation";

export default {
  props: [
    "id",
    "name",
    "color",
    "status",
    "total",
    "min",
    "max",
    "types",
    "action",
    "position",
    "cc",
    "vc",
    "platforms",
  ],
  data() {
    return {
      backgroundUrl,
    };
  },
  methods: {
    async changesportsstate() {
      var object = {
        status: !this.status,
      };

      await database.updatedoc(object, "sports", this.id);
    },

    async deletesport() {
      await database.deletedoc("sports", this.id);
    },

    editsport() {
      console.log("Push");
      this.$router.push({ path: `/sports/edit-sport/${this.id}` });
    },
  },
};
</script>

<style scoped>
.preview {
  border-radius: 1rem;
  min-height: 100px;
  margin: -1.5rem;
  margin-top: 1.5rem;
  padding: 1.5rem;
  padding-bottom: 0;
  color: #fff !important;
}
.preview.action {
  margin-bottom: 1.5rem;
}
.preview .row {
  padding-bottom: 1.5rem;
}
.preview h5 {
  color: #fff !important;
}
.actionbtn button {
  margin-bottom: 0;
}
.col-sm-4 {
  flex: 0 0 auto;
  width: 33.333333%;
}
.col-sm-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-sm-6 {
  flex: 0 0 auto;
  width: 50%;
}
h5,
.h5,
.h5 {
  font-size: 1rem;
}

.badge-secondary {
  color: #5974a2;
  background-color: #e4e8ed;
}
</style>