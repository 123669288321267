<template>
  <div class="card mb-2">
    <div class="card-header">
      <h6 class="mb-0">{{ data.lastFive.name }}</h6>
    </div>
    <div class="card-body p-3">
      <table class="table">
        <tbody>
          <tr v-for="team in data.lastFive.teams" :key="team">
            <td class="text-uppercase text-secondary text-xxs font-weight-bolder" :style="{ color: team.color }">{{ team.name }}</td>
            <td>
              <div class="d-flex gap-2">
                <div v-for="(select) in team.data" :key="select">
                  <span v-if="select == '-'" class="badge bg-gradient-light">{{ select }}</span>
                  <span v-if="select == 'W'" class="badge bg-gradient-success">{{ select }}</span>
                  <span v-if="select == 'L'" class="badge bg-gradient-danger">{{ select }}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h6 class="mb-0">{{ data.teamPerformance.name }}</h6>
    </div>
    <div class="card-body p-3">
      <table class="table">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Team</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Win</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Loss</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="team in data.teamPerformance.teams" :key="team">
            <td>
              <h6 :style="{ color: team.color }" class="mb-0 text-md"><b>{{ team.name }}</b></h6>
            </td>
            <td>
              <h6 class="mb-0 text-md"><b>{{ team.win }}</b></h6>
            </td>
            <td>
              <h6 class="mb-0 text-md"><b>{{ team.loss }}</b></h6>
            </td>
            <td>
              <h6 class="mb-0 text-md"><b>{{ team.total }}</b></h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
}
</script>

<style>
</style>