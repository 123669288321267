<template>
  <div class="row">
    <div class="col-12 mb-3" v-for="team in teams" :key="team">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-2">
              <Tshirt :color="team.color" />
            </div>
            <div class="col-9">
              <h4 class="mb-0">{{ team.name }}</h4>
              <p class="mb-0">{{ team.sortname }}</p>
            </div>
            <div class="col-1">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  @change="senddata"
                  v-model="selectedteams"
                  :value="{id:team.id, sortname:team.sortname}"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12" style="text-align:center">
      <button class="btn bg-gradient-secondary" @click="getdata()">Load More</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

import Tshirt from "../teams/Tshirt.vue";

export default {
  components: { Tshirt },
  props: ["sport", "selectedteams"],
  data() {
    return {
      limit: 0,
      teams: [],
    };
  },
  created() {
    this.getdata();
  },
  watch: {
    sport: function (newVal, oldVal) {
      this.getdata();
    },
  },
  methods: {
    async getdata() {
      const db = firebase.firestore();

      this.limit = this.limit + 50;

      var path = db.collection("teams").where("sport", "==", this.sport).orderBy("name").limit(this.limit);

      this.teams  = await database.getalldocs(path);
        
    },
    senddata(){
      this.$emit("senddata", this.selectedteams)
    }
  },
};
</script>

<style>
</style>