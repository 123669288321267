<template>
  <AddEditMatch :isnew="isnew" />
</template>

<script>

import AddEditMatch from "../../components/matches/Add-Edit-Match.vue"

export default {
  components: { AddEditMatch },
  data() {
    return {
      isnew: false,
    };
  },
};
</script>

<style>
</style>