<template>
  <router-view />

  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
   -->
</template>

<script>
import "./assets/css/nucleo-icons.css"
import "./assets/css/nucleo-svg.css"
import "./assets/css/soft-ui-dashboard.css"

export default {
   name: 'App',
};
</script>


<style>
.form-switch {
    padding: 0;
}
.text-right {
    text-align: right;
}
.green-text {
    color: #82d616;
}

.red-text {
    color: #ea0606;
}
.grey-text {
    color: #8392ab;
}
</style>
