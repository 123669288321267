import firebase from "firebase";

import SwlAlerts from "./swlalerts";

export default {

    async getalldocswithID(path) {
        SwlAlerts.swlloading();

        var alldocs = [];

        await path.get().then((querySnapshot) => {
            var tempdocs = [];
            querySnapshot.forEach((doc) => {
                tempdocs.push(doc);
            });
            alldocs = tempdocs;
        });

        SwlAlerts.swlclose();
        return alldocs;

    },

    async getalldocs(path) {
        SwlAlerts.swlloading();

        var alldocs = [];

        await path.get().then((querySnapshot) => {
            var tempdocs = [];
            querySnapshot.forEach((doc) => {
                tempdocs.push(doc.data());
            });
            alldocs = tempdocs;
        });

        SwlAlerts.swlclose();
        return alldocs;

    },

    async getdoc(path) {
        SwlAlerts.swlloading();

        let docdata = null;

        await path.get()
            .then((doc) => {
                if (doc.exists) {
                    docdata = { docid: doc.id, ...doc.data() }; // Add document ID here
                } else {
                    SwlAlerts.swlerror("Document not found");
                }
            })
            .catch((error) => {
                SwlAlerts.swlerror(error.message);
            });

        SwlAlerts.swlclose();
        return docdata;
    },

    async savedoc(object, collname, docid) {
        SwlAlerts.swlloading();
        const db = firebase.firestore();

        await db.collection(collname).doc(docid).set(object).then(() => {
            SwlAlerts.swldone(`${collname}  Successfully Added!`);
        }).catch((error) => {
            SwlAlerts.swlerror(error.message);
        });
        return true;
    },

    async savedocwithoutdoc(object, collname) {
        SwlAlerts.swlloading();
        const db = firebase.firestore();

        await db.collection(collname).doc().set(object).then(() => {
            SwlAlerts.swldone(`${collname}  Successfully Added!`);
        }).catch((error) => {
            SwlAlerts.swlerror(error.message);
        });
        return true;
    },

    async updatedoc(object, collname, docid) {
        SwlAlerts.swlloading();
        const db = firebase.firestore();

        await db.collection(collname).doc(docid).update(object).then(() => {
            SwlAlerts.swldone(`${collname}  Successfully Updated!`);
        }).catch((error) => {
            SwlAlerts.swlerror(error.message);
        });
        return true;
    },

    async deletedoc(collname, docid) {
        SwlAlerts.swlloading();
        const db = firebase.firestore();

        await db.collection(collname).doc(docid).delete()
            .then(() => {
                SwlAlerts.swldone(`${collname} Deleted!`);
            }).catch((error) => {
                SwlAlerts.swlerror(error.message);
            });
        return true;
    },

    async synccheck(id) {

        const db = firebase.firestore();

        const matchpath = db.collection("matches").doc(id.toString());
        const matchdatapath = db.collection("matches_data").doc(id.toString());

        var match = await this.getdoc(matchpath);
        var matchdata = await this.getdoc(matchdatapath);

        var players = matchdata.players;

        var d11datacheck = await this.datacheck(match, players, "dream11");
        var mt11datacheck = await this.datacheck(match, players, "myteam11");
        var m11cdatacheck = await this.datacheck(match, players, "my11circle");

        await this.updatedoc(
            {
                verify: {
                    dream11: d11datacheck,
                    myteam11: mt11datacheck,
                    my11circle: m11cdatacheck,
                },
            },
            "matches",
            id.toString()
        );

    },

    async datacheck(match, players, platform) {
        if (match.platforms[platform] != undefined) {
            if (match.series.platforms[platform] != undefined) {
                if (match.teams[0].platforms[platform] != undefined) {
                    if (match.teams[0].platforms[platform] != undefined) {
                        var playerdata = true;

                        for (const key in players) {
                            if (players[key].platforms[platform] != undefined) {
                                if (playerdata != false) {
                                    playerdata = true;
                                }
                            } else {
                                playerdata = false;
                            }
                        }

                        return playerdata;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    },

}