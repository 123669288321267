<template>
  <div
    :class="{'modal fade' : true, show: active}"
    :style="{'display': active ? 'block' : 'none'}"
    :id="subscription.orderId + '_view'"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="subscription.orderId + '_view'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Tag</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Data</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Bank Name</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.bankName }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Bank Txn ID</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.bankTxnId }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Day</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.day }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Display Name</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.displayName }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Email</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.email }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">End Date</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ timeright(subscription.endDate) }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Gateway Name</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.gatewayName }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Offer</span>
                  </td>
                  <td>
                    <div class="form-check form-switch ps-0">
                      <input
                        class="form-check-input ms-auto"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        v-model="subscription.offer"
                        disabled
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Offer Amount</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.offeramount }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Offer Name</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.offername }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Order ID</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.orderId }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Payment Mode</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.paymentMode }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Plan ID</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.planid }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Plan Name</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.planname }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Price</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.price }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Refund Amount</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.refundAmt }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Refund Text</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.refundtxt }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Start Date</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ timeright(subscription.startDate) }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">State</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.state }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Txn Amount</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.txnAmount }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Txn Date</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ timeright(subscription.txnDate) }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Txn ID</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.txnId }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Txn Type</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.txnType }}</b>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">User ID</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.uid }}</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="funselcator('viewsubscription')" type="button" class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-backdrop fade' : true, show: active}" :style="{'display': active ? 'block' : 'none'}"></div>
</template>

<script>
import TimeRight from "../../timeright";

export default {
  props: ["subscription", "active"],
  methods: {
    timeright(timedata) {
      var time = TimeRight.datemodifileryear(timedata);
      return time;
    },
    funselcator(funtext) {
      this.$emit(funtext);
    },
  },
};
</script>

<style>
</style>