<template>
  <div class="card">
    <div class="card-body">

      <img :src="image" class="img-fluid shadow border-radius-md mb-2" alt="">

      <p class="text-secondary mb-0 text-sm">
        {{ id }} | <a :href="source.url" target="_blank">{{ source.name }}</a>
        | {{ timeright() }}
      </p>

      <h5 class="font-weight-bolder">{{ title }}</h5>
      <p class="mb-2 text-sm">{{ content }}</p>
      <p class="mb-4 text-sm">{{ description }}</p>


      <div class="actionbtn" v-if="action">
        <div class="row">
          <div class="col-sm-4">
            <a :href="url" class="btn bg-gradient-info w-100 mb-0" target="_blank">View</a>
          </div>
          <div class="col-sm-4">
            <button type="button" class="btn bg-gradient-secondary w-100 mb-0" @click="editnews">Edit</button>
          </div>
          <div class="col-sm-4">
            <button type="button" class="btn bg-gradient-danger w-100 mb-0" @click="deletenews">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import TimeRight from "../../timeright.js";

export default {
  props: [
    "id",
    "content",
    "description",
    "image",
    "publishedAt",
    "source",
    "time",
    "title",
    "url",
    "action",
  ],
  data() {
    return {

    };
  },
  methods: {


    timeright() {
      
      var time = TimeRight.datemodifiler(this.time);

      return time;
    },
    async deletenews() {
      await database.deletedoc("news", this.id);
    },

    editnews() {
      console.log("Push");
      this.$router.push({ path: `/news/edit-news/${this.id}` });
    },
  },
};
</script>
