<template>
  <div class="row">
    <div class="col-xl-3 mb-3" v-for="state in states" :key="state">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-8">
              <h5 class="card-title mb-0">{{ state }}</h5>
              <p class="card-description mb-0">{{ country }}</p>
            </div>
            <div class="col-xl-4">
              <a
                href="#"
                class="btn btn-danger btn-sm mb-0 w-100"
                @click.prevent="removestate(state)"
              >Delete</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12 mb-3">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="updatestate">
            <div class="row">
              <div class="col-xl-5">
                <div class="mb-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Country"
                    disabled
                    v-model="country"
                    required
                  />
                </div>
              </div>
              <div class="col-xl-5">
                <div class="mb-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="State"
                    v-model="state"
                    required
                  />
                </div>
              </div>
              <div class="col-xl-2" style="text-align:right;">
                <button type="submit" class="btn bg-gradient-info mb-0 w-100">
                  <span>Add</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  data() {
    return {
      country: "India",
      state: "",
      states: [],
    };
  },
  async created() {
    await this.getdata();
  },
  methods: {
    async updatestate() {
      var object = {
        india: firebase.firestore.FieldValue.arrayUnion(this.state),
      };
      await database.updatedoc(object, "manage_app", "country_state");
      await this.getdata();
    },
    async removestate(state) {
      var object = {
        india: firebase.firestore.FieldValue.arrayRemove(state),
      };
      await database.updatedoc(object, "manage_app", "country_state");
      await this.getdata();
    },
    async getdata() {
      var db = firebase.firestore();

      var path = db.collection("manage_app").doc("country_state");

      var data = await database.getdoc(path);

      this.states = data.india;
    },
  },
};
</script>