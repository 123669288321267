<template>
  <div class="card mb-3" :class="{ playing : match.playing }">
    <div class="card-body">
      <div class="row align-items-center mb-2">
        <div class="col-7">
          <h6 style="margin:0;">{{ match.series.name }}</h6>
        </div>
        <div class="col-5" v-if="match.playing">
          <p style="margin:0; text-align:right; color: #A9DD3A;">● Lineup Out</p>
        </div>
        <div class="col-5" v-else>
          <p style="margin:0; text-align:right;">{{ timeright() }}</p>
        </div>
      </div>
      <div class="row teamdata align-items-center">
        <div class="col left">
          <span class="colorhint" :style="{ 'background-color':match.teams[0].color }"></span>
          <span class="tshirt">
            <Tshirt :color="match.teams[0].color" />
          </span>
          <span
            class="teamname"
            :style="{ color:match.teams[0].color }"
          >{{ match.teams[0].sortname }}</span>
        </div>
        <div class="col center" style="text-align:center">
          <span class="timer">{{ timeerdata() }}</span>
        </div>
        <div class="col right">
          <span
            class="teamname"
            :style="{ color:match.teams[1].color }"
          >{{ match.teams[1].sortname }}</span>
          <span class="tshirt">
            <Tshirt :color="match.teams[1].color" />
          </span>
          <span class="colorhint" :style="{ 'background-color':match.teams[1].color }"></span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="platfrom_avlabel" v-if="match.verify != null">
            <img src="../../assets/img/platforms/d11.png" v-if="match.verify.dream11" />
            <img src="../../assets/img/platforms/mt11.png" v-if="match.verify.myteam11" />
            <img src="../../assets/img/platforms/m11c.png" v-if="match.verify.my11circle" />
          </div>
          <div class="platfrom_avlabel" v-else>
            <img src="../../assets/img/platforms/d11.png" />
          </div>
        </div>
      </div>

      <div class="actionbtn" v-if="action">
        <div class="row">
          <div class="col">
            <button
              type="button"
              class="btn bg-gradient-secondary w-100 mb-0 mt-3"
              @click="editplayer"
            >Edit</button>
          </div>

          <div class="col" v-if="match.players == false">
            <button
              type="button"
              class="btn bg-gradient-info w-100 mb-0 mt-3"
              @click="getpalyers"
            >Get Palyers</button>
          </div>

          <div class="col" v-if="match.players == true">
            <button
              type="button"
              class="btn bg-gradient-success w-100 mb-0 mt-3"
              @click="getpalyers"
            >Playing Out</button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn bg-gradient-danger w-100 mb-0 mt-3"
              @click="deleteplayer"
            >Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import sweetalert from "../../swlalerts";
import TimeRight from "../../timeright.js";
import database from "../../databasefuncation";

import Tshirt from "../../components/teams/Tshirt.vue";

export default {
  components: { Tshirt },
  props: ["match", "action", "allNews"],
  methods: {
    async deleteplayer() {
      await database.deletedoc("matches", this.match.id.toString());
    },

    editplayer() {
      this.$router.push({ path: `/matches/edit-match/${this.match.id}` });
    },

    timeright() {
      var time = TimeRight.datemodifiler(this.match.time);
      return time;
    },

    timeerdata() {
      var time = TimeRight.datetimer(this.match.time);
      return time;
    },

    async getpalyers() {
      sweetalert.swlloading();

      var hostname =
        location.hostname === "localhost"
          ? "http://localhost:3000"
          : "https://proapi2.teammaster.in";

      this.axios
        .get(
          `${hostname}/getplayer?id=${this.match.id}&tourId=${this.match.series.did}&matchId=${this.match.did}&sport=${this.match.sport}`
        )
        .then((response) => {
          sweetalert.swldone(response.data);
        })
        .catch((error) => {
          sweetalert.swlerror(error);
        });
    },

    playingout() {},
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
.teamdata .col {
  display: flex;
  position: relative;
  align-items: center;
}
.teamdata .col.right {
  justify-content: flex-end;
}

.teamdata .colorhint {
  width: 4px;
  height: 24px;
  display: block;
  background-color: gray;
  position: absolute;
  top: calc(50% - 12px);
}
.teamdata .left .colorhint {
  left: -12px;
  border-radius: 0 4px 4px 0;
}
.teamdata .right .colorhint {
  right: -12px;
  border-radius: 4px 0 0 4px;
}
.teamdata .col.center {
  justify-content: center;
}

.teamdata .left .tshirt {
  margin-right: 8px;
}

.teamdata .right .tshirt {
  margin-left: 8px;
}

.teamdata .tshirt {
  width: 64px;
  display: block;
}
.teamdata span.teamname {
  font-size: 18px;
  font-weight: bold;
}

.teamdata span.timer {
  font-size: 16px;
  font-weight: bold;
  color: #22292b;
}

.playing {
  border: 4px solid #a9dd3a;
}
.platfrom_avlabel img {
  width: 40px;
}
</style>