<template>
  <div class="card mt-4">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">History</h6>
        </div>
        <div class="col-6 d-flex align-items-center justify-content-end">
          <button
            @click="getmorehistory"
            type="button"
            class="btn bg-gradient-success btn-sm mb-0"
          >Load More</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card mt-4">
    <div class="card-body p-3">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Device ID</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Physical Device</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Platfrom</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(history, index) in histores" :key="(history, index)">
              <td>
                <span class="text-secondary  font-weight-bold">{{ history.deviceid }}</span>
              </td>
              <td>
                <div class="form-check form-switch ps-0">
                  <input
                    class="form-check-input ms-auto"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    v-model="history.isPhysicalDevice"
                    disabled
                  />
                </div>
              </td>
              <td>
                <span class="text-secondary  font-weight-bold">{{ history.platfrom }}</span>
              </td>
              <td>
                <span class="text-secondary  font-weight-bold">{{ timeright(history.time) }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import TimeRight from "../../timeright";

export default {
  props: ["uid"],
  data() {
    return {
      limit: 10,
      histores: [],
    };
  },
  async created() {
    this.gethistory();
  },
  methods: {
    async gethistory() {
      const db = firebase.firestore();
      var path = db
        .collection("users")
        .doc(this.uid)
        .collection("history")
        .orderBy("time", "desc")
        .limit(this.limit);

      this.histores = await database.getalldocs(path);
    },
    getmorehistory() {
      this.limit += 10;
      this.gethistory();
    },
    timeright(timeold) {
      var time = TimeRight.datemodifileryear(timeold);
      return time;
    },
  },
};
</script>

<style>
</style>