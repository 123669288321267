<template>
  <h3>All Platfrom</h3>

  <div class="row">
    <div class="col-xl-4 mb-4" v-for="platform in platforms" :key="platform">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6 align-left">
              <p class="m-0">ID</p>
              <h5>
                <b>{{ platform.id }}</b>
              </h5>
            </div>

            <div class="col-sm-6 align-left">
              <p class="m-0">Name</p>
              <h5>
                <b>{{ platform.name }}</b>
              </h5>
            </div>

            <div class="col-sm-6 align-left">
              <p class="m-0">Default</p>
              <h5>
                <b>{{ platform.default }}</b>
              </h5>
            </div>

            <div class="col-sm-6 align-left">
              <p class="m-0">Position</p>
              <h5>
                <b>{{ platform.position }}</b>
              </h5>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <button
                type="button"
                class="btn bg-gradient-secondary w-100 mb-0"
                @click="editplatfrom(platform)"
              >Edit</button>
            </div>
            <div class="col-sm-6">
              <button
                type="button"
                class="btn bg-gradient-danger w-100 mb-0"
                @click="deleteplatfrom(platform.id)"
              >Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h3>Add New Platfrom</h3>
  <div class="card">
    <div class="card-body p-3">
      <form @submit.prevent="saveplatfrom">
        <div class="row">
          <div class="col-md-3">
            <label>Id</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Platfrom Id"
                v-model="id"
                v-bind:disabled="!isnew"
                required
              />
            </div>
          </div>

          <div class="col-md-3">
            <label>Name</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Platfrom Name"
                v-model="name"
                required
              />
            </div>
          </div>

          <div class="col-md-3">
            <label>Position</label>
            <div class="mb-3">
              <select class="form-control" v-model.number="position">
                <option value>Select Position</option>
                <option value="1">First</option>
                <option value="2">Secound</option>
                <option value="3">Third</option>
                <option value="4">Fourth</option>
                <option value="5">Fifth</option>
                <option value="6">Sixth</option>
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <label>Default</label>
            <div class="mt-1 mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" v-model="bydefault" />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <button type="submit" class="btn bg-gradient-info mb-0">
              <span v-if="isnew">Save Platfrom</span>
              <span v-else>Update Platfrom</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import firebase from "firebase";
import SwlAlerts from "../../swlalerts";

import database from "../../databasefuncation";

export default {
  data() {
    return {
      id: "",
      name: "",
      bydefault: false,
      position: "",
      isnew: true,
      platforms: [],
    };
  },

  created() {
    this.getalldata();
  },

  methods: {

    async getalldata() {
      const db = firebase.firestore();
      var path = db.collection("platforms").orderBy("position");
      this.platforms = await database.getalldocs(path);
    },

    async saveplatfrom() {
      const object = {
        id: this.id.toLowerCase(),
        name: this.name,
        default: this.bydefault,
        position: this.position,
      };

      if (this.isnew) {
        await database.savedoc(object, "platforms", this.id.toLowerCase());
        this.getalldata();
      } else {
        await database.updatedoc(object, "platforms", this.id.toLowerCase());
        this.isnew = true;
        this.getalldata();
      }
    },

    editplatfrom(platfrom) {
      this.id = platfrom.id;
      this.name = platfrom.name;
      this.bydefault = platfrom.default;
      this.position = platfrom.position;

      this.isnew = false;
    },

    async deleteplatfrom(id) {
      await database.deletedoc("platforms", id);
      this.getalldata();
    },
  },
};
</script>


<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>