<template>
  <div class="card">
    <div class="card-body">
      <table class="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <td></td>
            <td>
              <div class="d-flex px-2 py-1">
                <div>
                  <img :src="require(`@/assets/img/prediction/paceBowling.svg`)" class="avatar avatar-sm me-3" alt="user1">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Pacers</h6>
                </div>
              </div>

            </td>
            <td>
              <div class="d-flex px-2 py-1">
                <div>
                  <img :src="require(`@/assets/img/prediction/spinBowling.svg`)" class="avatar avatar-sm me-3" alt="user1">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Spinners</h6>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="rowdata in data" :key="rowdata">
            <td>
              <h6 class="mb-0 text-sm">{{ rowdata.name }}</h6>
            </td>
            <td>
              <h6 class="mb-0 text-sm"><b>{{ rowdata.pacers }}</b></h6>
            </td>
            <td>
              <h6 class="mb-0 text-sm"><b>{{ rowdata.spinners }}</b></h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
}
</script>

<style>
</style>