<template>
  <nav class="navbar navbar-vertical mb-3 p-0">
    <ul class="navbar-nav">
      <li class="nav-item" v-for="sport in sports" :key="sport">
        <a href="javascript:void(0)" v-on:click="changesporttab(sport.id)" :class="{'nav-link' : true, active: (sport.id == activeport)}">{{sport.name}}</a>
      </li>
    </ul>
  </nav>

  <nav class="navbar navbar-vertical mb-3 p-0">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a href="javascript:void(0)" v-on:click="changetab()" :class="{'nav-link' : true, active: upcoming}">Upcoming</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0)" v-on:click="changetab()" :class="{'nav-link' : true, active: !upcoming}">Completed</a>
      </li>
    </ul>
  </nav>

  <div class="row">
    <div class="col-xl-2">
      <select class="form-control" v-model.number="pageNo">
        <option value="1">Page 1</option>
        <option value="2">Page 2</option>
        <option value="3">Page 3</option>
        <option value="4">Page 4</option>
        <option value="5">Page 5</option>
        <option value="6">Page 6</option>
        <option value="7">Page 7</option>
        <option value="8">Page 8</option>
        <option value="9">Page 9</option>
      </select>
    </div>
    <div class="col-xl-10">
      <button class="btn bg-gradient-success" @click="setallmatches()" style="margin-right:10px">Update Matches</button>
      <button class="btn bg-gradient-info" @click="setallplayers()">Update Players</button>
    </div>
    <div class="col-xl-4 mb-4" v-for="match in matches" :key="match">
      <MatchPreview :match="match" :action="action" />
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12" style="text-align:center">
      <button class="btn bg-gradient-secondary" @click="getmore()">Load More</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import sweetalert from "../../swlalerts";

import MatchPreview from "../../components/matches/Match-Preview.vue";

export default {
  components: { MatchPreview },
  data() {
    return {
      matches: [],
      action: true,
      upcoming: true,
      limit: 0,
      pageNo: 1,
      sports: [],
      activeport: "cricket",
    };
  },
  async created() {
    const db = firebase.firestore();

    var path = db.collection("sports").orderBy("position");

    this.sports = await database.getalldocs(path);

    this.getalldata();
  },
  methods: {
    changetab() {
      this.upcoming = !this.upcoming;
      this.getalldata();
    },
    changesporttab(id) {
      this.limit = 0;
      this.activeport = id;
      this.getalldata();
    },
    getalldata() {
      this.limit = 50;

      const db = firebase.firestore();
      var currentdate = firebase.firestore.Timestamp.now().toDate();

      var path = this.upcoming
        ? db
          .collection("matches")
          .where("sport", "==", this.activeport)
          .where("time", ">", currentdate)
          .orderBy("time")
          .limit(this.limit)
        : db
          .collection("matches")
          .where("sport", "==", this.activeport)
          .where("time", "<", currentdate)
          .orderBy("time")
          .limit(this.limit);

      path.onSnapshot((querySnapshot) => {
        var matchestemp = [];

        querySnapshot.forEach((doc) => {
          matchestemp.push(doc.data());
        });

        matchestemp.sort(function (a, b) {
          return b.highlight - a.highlight;
        });

        this.matches = matchestemp;
      });
    },
    getmore() {
      this.limit = this.limit + 50;
      this.getalldata();
    },

    async setallmatches() {
      sweetalert.swlloading();

      var hostname = (location.hostname === "localhost") ? "http://localhost:3000" : "https://proapi2.teammaster.in";

      

      this.axios
        .get(`${hostname}/setalldata?sport=${this.activeport}&pageNo=${this.pageNo}`)
        .then((response) => {
          sweetalert.swldone(response.data);
        })
        .catch((error) => {
          sweetalert.swlerror(error);
        });
    },


    async setallplayers() {
      sweetalert.swlloading();

      var hostname = (location.hostname === "localhost") ? "http://localhost:3000" : "https://proapi2.teammaster.in";

      this.axios
        .get(`${hostname}/getalllayers`)
        .then((response) => {
          sweetalert.swldone(response.data);
        })
        .catch((error) => {
          sweetalert.swlerror(error);
        });
    },

  },
};
</script>


<style scoped>
.navbar {
  display: block;
  box-shadow: none;
}

.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-nav .nav-item {
  width: 100%;
}

.navbar-nav .nav-item .nav-link {
  text-align: center;
  display: block;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>