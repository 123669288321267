<template>
  <div
    :class="{'modal fade' : true, show: active}"
    :style="{'display': active ? 'block' : 'none'}"
    :id="data.id"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="data.id"
    aria-hidden="true"
  >
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="modal-title-default">Add Edit Team</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="saveteam">
            <div class="row">
              <div class="col-xl-4">
                <label>ID</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Team Id"
                    v-model.number="data.id"
                    v-bind:disabled="!isnew"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Dream11 ID</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Dream11 Team Id"
                    v-model.number="data.did"
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Name</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Team Name"
                    v-model="data.name"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Sort Name</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Team Sort Name"
                    v-model="data.sortname"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Color</label>
                <div class="mb-3">
                  <input
                    style="height : 40px"
                    type="color"
                    class="form-control"
                    placeholder="Team Color"
                    v-model="data.color"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Sport</label>
                <div class="mb-3">
                  <select class="form-control" v-model="data.sport" required>
                    <option :value="sport.id" v-for="sport in sports" :key="sport">{{ sport.id }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row" v-for="(series, index) in data.seriess" :key="(series, index)">
              <div class="col-xl-6">
                <div class="mb-2">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="Series Id"
                    v-model.number="series.id"
                    required
                  />
                </div>
              </div>
              <div class="col-xl-6">
                <div class="mb-3">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="Series Name"
                    v-model="series.name"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12">
                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save Team</span>
                  <span v-else>Update Team</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            @click="closeModal('closeModal')"
            type="button"
            class="btn btn-link ml-auto mb-0"
            data-bs-dismiss="modal"
          >Close</button>
        </div>
      </div>
    </div>
  </div>
  <div
    :class="{'modal-backdrop fade' : true, show: active}"
    :style="{'display': active ? 'block' : 'none'}"
  ></div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  props: ["isnew", "active", "data", "sports"],
  data() {
    return {
      action: false,
      oldTeam: null,
    };
  },
  created() {
    // this.oldTeam.push(Vue.util.extend({}, this.data));

    var data = JSON.parse(JSON.stringify(this.data));

    this.oldTeam = data;
  },
  methods: {
    closeModal(funtext) {
      this.$emit(funtext);
    },

    addseries(data) {
      this.seriess = data;
    },

    async saveteam() {
      var db = firebase.firestore();

      //Update Palyer Data

      // Find All Players
      var palyerPath = db
        .collection("players")
        .where("teams", "array-contains", {
          id: this.oldTeam.id,
          sortname: this.oldTeam.sortname,
        });

      var players = await database.getalldocs(palyerPath);

      for (const player of players) {
        var teams = player.teams;

        for (const team of teams) {
          if (
            team.id == this.oldTeam.id &&
            team.sortname == this.oldTeam.sortname
          ) {
            team.id = this.data.id;
            team.sortname = this.data.sortname;
          }
        }

        await database.updatedoc(
          { teams: teams },
          "players",
          player.id.toString()
        );
      }

      //Delete Map entry
      delete this.oldTeam.seriess;
      delete this.oldTeam.sport;

      var matchesPath = db
        .collection("matches")
        .where("teams", "array-contains", this.oldTeam);

      var matches = await database.getalldocs(matchesPath);
      
      for (const match of matches) {
        var teams = match.teams;

        for (const team of teams) {
          if (team.id == this.oldTeam.id) {
            team.color = this.data.color;
            team.did = this.data.did;
            team.id = this.data.id;
            team.name = this.data.name;
            team.sortname = this.data.sortname;
            team.platforms = this.data.platforms;
          }
        }

        var matchDataPath = db
          .collection("matches_data")
          .doc(match.id.toString());

        var matchData = await database.getdoc(matchDataPath);

        var allPlayers = matchData.players;

        for (const player of allPlayers) {
          if (player.team == this.oldTeam.sortname) {
            player.team = this.data.sortname;
          }
        }

        await database.updatedoc(
          { players: allPlayers },
          "matches_data",
          match.id.toString()
        );

        await database.updatedoc(
          { teams: teams },
          "matches",
          match.id.toString()
        );
      }

      var object = {
        id: this.data.id,
        did: this.data.did,
        name: this.data.name,
        sortname: this.data.sortname,
        color: this.data.color,
        sport: this.data.sport,
        seriess: this.data.seriess,
        platforms: this.data.platforms,
      };

      await database.updatedoc(object, "teams", this.data.id.toString());
    },
  },
};
</script>

<style>
</style>