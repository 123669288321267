<template>
  <div class="row">
    <div class="col-md-12 mb-lg-0 mb-4">
      <div class="card mt-4">
        <form @submit.prevent="finduser">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-12 d-flex align-items-center">
                <h6 class="mb-0">Find User</h6>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-10">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email Id"
                  v-model="email"
                  required
                />
              </div>
              <div class="col-2">
                <button type="submit" class="btn bg-gradient-info mb-0 w-100">
                  <span>Find Now</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-md-12 mb-lg-0 mb-4" v-if="user">
      <div class="card card-body mt-4">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative primeuser">
              <img
                :src="user.photoURL"
                alt="profile_image"
                class="w-100 border-radius-lg shadow-sm"
              />
              <img v-if="subscription" class="prime" src="@/assets/img/img_primeuser.png" />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ user.displayName }}</h5>
              <p class="coin">
                <img src="@/assets/img/coin.svg">
                {{ user.coins }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <nav class="navbar navbar-vertical p-0">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a
                    href="javascript:void(0)"
                    v-on:click="changetab('subscription')"
                    :class="{'nav-link' : true, active: (tab == 'subscription')}"
                  >Subscription</a>
                </li>
                
                <li class="nav-item">
                  <a
                    href="javascript:void(0)"
                    v-on:click="changetab('history')"
                    :class="{'nav-link' : true, active: (tab == 'history')}"
                  >History</a>
                </li>
                
                <li class="nav-item">
                  <a
                    href="javascript:void(0)"
                    v-on:click="changetab('notification')"
                    :class="{'nav-link' : true, active: (tab == 'notification')}"
                  >Notification</a>
                </li>
                
                <li class="nav-item">
                  <a
                    href="javascript:void(0)"
                    v-on:click="changetab('transaction')"
                    :class="{'nav-link' : true, active: (tab == 'transaction')}"
                  >Transaction</a>
                </li>

              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 mb-lg-0 mb-4" v-if="user">
      <Info :key="user" :user="user" />
    </div>

    <div class="col-md-8 mb-lg-0 mb-4" v-if="user">
      <Subscription
        :key="user"
        :subscription="subscription"
        :uid="user.uid"
        :user="user"
        v-if="tab == 'subscription'"
      />
      <History :key="user" :uid="user.uid" v-if="tab == 'history'" />
      <Notification :key="user" :uid="user.uid" v-if="tab == 'notification'" />
      <Transaction :key="user" :uid="user.uid" v-if="tab == 'transaction'" />

    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import TimeRight from "../../timeright";
import database from "../../databasefuncation";
import sweetalert from "../../swlalerts";

import Info from "../../components/users/info.vue";
import History from "../../components/users/history.vue";
import Subscription from "../../components/users/subscription.vue";
import Notification from "../../components/users/notification.vue";
import Transaction from "../../components/users/transaction.vue";

export default {
  components: { Info, History, Subscription, Notification, Transaction },
  data() {
    return {
      tab: "subscription",
      email: "contacttopkr@gmail.com",
      user: null,
      subscription: false,
    };
  },
  methods: {
    finduser() {
      this.user = null;
      this.subscription = false;

      this.limit = 1;

      const db = firebase.firestore();

      var path = db
        .collection("users")
        .where("email", "==", this.email)
        .limit(this.limit);

      path.onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.user = doc.data();
          this.subscription = TimeRight.checksubscription(
            this.user.subscriptionEnd
          );
        });
      });
    },

    changetab(name) {
      this.tab = name;
    },
  },
};
</script>

<style scoped>
.primeuser {
  position: relative;
}
.primeuser .prime {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.navbar {
  display: block;
  box-shadow: none;
}
.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-nav .nav-item {
  width: 100%;
}
.navbar-nav .nav-item .nav-link {
  text-align: center;
  display: block;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.coin{
  display: flex;
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  color: #ff9501;
}
.coin img{
  width: 32px;
  margin-right: 10px;
}
</style>