<template>
  <div class="row">
    <div class="col-xl-8">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="saveseries">
            <div class="row">
              <div class="col-xl-3">
                <label>ID</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Series Id"
                    v-model.number="id"
                    v-bind:disabled="!isnew"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-3">
                <label>Dream11 ID</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Dream11 Series Id"
                    v-model.number="did"
                  />
                </div>
              </div>

              <div class="col-xl-3">
                <label>Name</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Series Name"
                    v-model="name"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-3">
                <label>Sport</label>
                <div class="mb-3">
                  <select class="form-control" v-model="sport" required>
                    <option :value="sport" v-for="sport in sports" :key="sport">{{ sport }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save Series</span>
                  <span v-else>Update Series</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <SeriesPreview :did="did" :id="id" :name="name" :sport="sport" :action="action" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

import SeriesPreview from "../../components/series/Series-Preview.vue";

export default {
  props: ["isnew"],
  components: { SeriesPreview },
  data() {
    return {
      id: null,
      did: null,
      name: "",
      oldName: "",
      sport: "",
      platforms: {},
      sports: [],
      action: false,
    };
  },
  async created() {
    const db = firebase.firestore();

    if (this.isnew) {
      var path = db.collection("series").orderBy("id", "desc").limit(1);

      var alldocs = await database.getalldocs(path);

      if (alldocs.length == 0) {
        this.id = 0;
      } else {
        alldocs.forEach((doc) => {
          this.id = parseInt(doc.id) + 1;
        });
      }
    } else {

      this.id = this.$route.params.id;

      var path = db.collection("series").doc(this.id);

      var series = await database.getdoc(path);

      this.id = series.id;
      this.did = series.did;
      this.name = series.name;
      this.oldName = series.name;
      this.sport = series.sport;
      this.platforms = series.platforms;
    }

    var path = db.collection("sports").orderBy("position");

    var allsports = await database.getalldocs(path);

    allsports.forEach((doc) => {
      this.sports.push(doc.id);
    });
  },
  methods: {
    async saveseries() {
      const object = {
        id: this.id,
        did: this.did,
        name: this.name,
        sport: this.sport,
        platforms: this.isnew ? { dream11: { id: this.did } } : this.platforms,
      };

      if (this.isnew) {
        database.savedoc(object, "series", this.id.toString());
      } else {
        const db = firebase.firestore();

        var matchesPath = db
          .collection("matches")
          .where("series.id", "==", this.id);

        var allMatches = await database.getalldocs(matchesPath);

        for (const match of allMatches) {
          await database.updatedoc(
            {
              series: {
                did: this.did,
                id: this.id,
                name: this.name,
                platforms : match.series.platforms,
              },
            },
            "matches",
            match.id.toString()
          );
        }

        var teamsPath = db
          .collection("teams")
          .where("seriess", "array-contains", {
            id: this.id,
            name: this.oldName,
          });

        var allTeams = await database.getalldocs(teamsPath);
        
        for (const team of allTeams) {

          console.log(team.seriess);

          var allSeriess = [];
          
          for (const series of team.seriess) {
            if (series.id == this.id) {
              allSeriess.push({
                id: this.id,
                name: this.name,
              });
            } else {
              allSeriess.push(series);
            }
          }

          await database.updatedoc(
            {
              seriess: allSeriess,
            },
            "teams",
            team.id.toString()
          );
          
        }

        database.updatedoc(object, "series", this.id.toString());
      }
    },
  },
};
</script>

<style>
</style>