<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="bodymain nodatacard" v-if="match.empty">
        <span class="text">Blank Card</span>
      </div>

      <div class="bodymain" v-else>
        <div class="row align-items-center">
          <div class="col-7">
            <h6 style="margin:0;">{{ match.seriesDname }}</h6>
          </div>
          <div class="col-5">
            <p style="margin:0; text-align:right;">{{ timeright() }}</p>
          </div>
        </div>
        <div class="row teamdata align-items-center">
          <div class="col left">
            <span class="colorhint"></span>
            <span class="tshirt">
              <img :src="match.team1.teamFlagURL" />
            </span>
            <span class="teamname">{{ match.team1.dName }}</span>
          </div>
          <div class="col center" style="text-align:center">
            <span class="timer">{{ timeerdata() }}</span>
          </div>
          <div class="col right">
            <span class="teamname">{{ match.team2.dName }}</span>
            <span class="tshirt">
              <img :src="match.team2.teamFlagURL" />
            </span>
            <span class="colorhint"></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-success w-100 mb-0 mt-3"
            @click="swapteam"
          >Swap Team</button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn bg-gradient-danger w-100 mb-0 mt-3"
            @click="deleteself"
          >Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

import SwlAlerts from "../../../swlalerts.js";
import TimeRight from "../../../timeright.js";

export default {
  props: ["match", "index"],
  methods: {
    deleteself() {
      this.$emit("deletemy11criclematch", this.index);
    },
    swapteam() {
      this.$emit("swapmy11cteam", this.index);
    },
    timeright() {
      var gettime = new Date(this.match.matchFreezeTime);
      var currenttime = firebase.firestore.Timestamp.fromDate(gettime);
      var time = TimeRight.datemodifiler(currenttime);
      return time;
    },
    timeerdata() {
      var gettime = new Date(this.match.matchFreezeTime);
      var currenttime = firebase.firestore.Timestamp.fromDate(gettime);
      var time = TimeRight.datetimer(currenttime);
      return time;
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
.teamdata .col {
  display: flex;
  position: relative;
  align-items: center;
}
.teamdata .col.right {
  justify-content: flex-end;
}

.teamdata .colorhint {
  width: 4px;
  height: 24px;
  display: block;
  background-color: gray;
  position: absolute;
  top: calc(50% - 12px);
}
.teamdata .left .colorhint {
  left: -12px;
  border-radius: 0 4px 4px 0;
}
.teamdata .right .colorhint {
  right: -12px;
  border-radius: 4px 0 0 4px;
}
.teamdata .col.center {
  justify-content: center;
}

.teamdata .left .tshirt {
  margin-right: 8px;
}

.teamdata .right .tshirt {
  margin-left: 8px;
}

.teamdata .tshirt {
  width: 64px;
  display: block;
}
.teamdata span.teamname {
  font-size: 18px;
  font-weight: bold;
}

.teamdata span.timer {
  font-size: 16px;
  font-weight: bold;
  color: #22292b;
}
.nodatacard {
  border: 2px dashed #ccc;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #ccc;
  font-size: 20px;
  padding: 100px;
  border-radius: 10px;
}
</style>