<template>
    <div class="card mb-3">
        <div class="card-body">
            <div class="row teamdata align-items-center">
                <div class="col left">
                    <span class="colorhint" :style="{ 'background-color': prediction.teams[0].color }"></span>
                    <span class="tshirt">
                        <Tshirt :color="prediction.teams[0].color" />
                    </span>
                    <span class="teamname" :style="{ color: prediction.teams[0].color }">{{ prediction.teams[0].sortname }}</span>
                </div>
                <div class="col center" style="text-align:center">
                    <span class="timer">{{ timeerdata() }}</span>
                </div>
                <div class="col right">
                    <span class="teamname" :style="{ color: prediction.teams[1].color }">{{ prediction.teams[1].sortname }}</span>
                    <span class="tshirt">
                        <Tshirt :color="prediction.teams[1].color" />
                    </span>
                    <span class="colorhint" :style="{ 'background-color': prediction.teams[1].color }"></span>
                </div>
            </div>


            <div class="mt-3 backgroundDiv">
                <div class="indata">
                    <h4 class="m-0">{{ prediction.stadium }}</h4>
                    <h6 class="m-0">{{ timeright() }}, {{ prediction.location }}</h6>
                </div>
            </div>


            <div class="actionbtn" v-if="action">
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn bg-gradient-secondary w-100 mb-0 mt-3" @click="editplayer">Edit</button>
                    </div>
                    <div class="col">
                        <button type="button" class="btn bg-gradient-danger w-100 mb-0 mt-3" @click="deleteplayer">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase";
import sweetalert from "../../swlalerts";
import TimeRight from "../../timeright.js";
import database from "../../databasefuncation";

import Tshirt from "../teams/Tshirt.vue";

export default {
    components: { Tshirt },
    props: ["prediction", "action"],
    methods: {
        async deleteplayer() {
            await database.updatedoc({ prediction: false }, "matches", this.prediction.id.toString());

            await database.deletedoc("prediction", this.prediction.id.toString());
        },

        editplayer() {
            this.$router.push({ path: `/prediction/edit-prediction/${this.prediction.id}` });
        },

        timeright() {
            var time = TimeRight.datemodifiler(this.prediction.time);
            return time;
        },

        timeerdata() {
            var time = TimeRight.datetimer(this.prediction.time);
            return time;
        },


    },
};
</script>

<style scoped>
img {
    max-width: 100%;
}

.backgroundDiv {
    background-image: url("../../assets/img/prediction/background.jpg");
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.indata {
    padding: 1.5rem;
    background: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 100%);
    min-height: 120px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.indata h4, .indata h6{
    color: #fff;
}

.teamdata .col {
    display: flex;
    position: relative;
    align-items: center;
}

.teamdata .col.right {
    justify-content: flex-end;
}

.teamdata .colorhint {
    width: 4px;
    height: 24px;
    display: block;
    background-color: gray;
    position: absolute;
    top: calc(50% - 12px);
}

.teamdata .left .colorhint {
    left: -12px;
    border-radius: 0 4px 4px 0;
}

.teamdata .right .colorhint {
    right: -12px;
    border-radius: 4px 0 0 4px;
}

.teamdata .col.center {
    justify-content: center;
}

.teamdata .left .tshirt {
    margin-right: 8px;
}

.teamdata .right .tshirt {
    margin-left: 8px;
}

.teamdata .tshirt {
    width: 64px;
    display: block;
}

.teamdata span.teamname {
    font-size: 18px;
    font-weight: bold;
}

.teamdata span.timer {
    font-size: 16px;
    font-weight: bold;
    color: #22292b;
}

.playing {
    border: 4px solid #a9dd3a;
}

.platfrom_avlabel img {
    width: 40px;
}
</style>