<template>
  <Sidebar :pined="pined" @toggelnav="navshowhind" />
  <main class="main-content position-relative mt-1 border-radius-lg">
    <Navbar @toggelnav="navshowhind" />
    <div class="container-fluid py-4">
      <router-view />
      <Footer />
    </div>
  </main>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Dashboard",
  data() {
    return {
      pined: false,
    };
  },
  components: {
    Sidebar,
    Navbar,
    Footer,
  },
  watch: {
    pined: function () {
      const el = document.body;
      if (this.pined) {
        el.classList.add("g-sidenav-pinned");
      } else {
        el.classList.remove("g-sidenav-pinned");
      }
    },
  },
  methods: {
    navshowhind() {
      this.pined = !this.pined;
    },
  },
};
</script>

<style>
</style>