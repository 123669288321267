<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }" :id="add_other_prediction" tabindex="-1" role="dialog" :aria-labelledby="add_other_prediction"
        aria-hidden="true">
        <div v-if="active" class="modal-dialog modal- modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">{{ data.name }}</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <h6>Probable PLayers</h6>
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Player</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Role</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Team</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Select</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(player, index) in data.data" :key="player">
                                        <td>
                                            <h6 class="mb-0 text-xs">{{ player.name }}</h6>
                                        </td>
                                        <td>
                                            <h6 class="mb-0 text-xs">{{ player.type }}</h6>
                                        </td>
                                        <td>
                                            <h6 class="mb-0 text-xs">{{ player.team }}</h6>
                                        </td>
                                        <td>
                                            <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;" @click="removedata(index)"><i class="far fa-trash-alt me-2" aria-hidden="true"></i>Remove</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-6">
                            <h6>Total Players</h6>
                            <div class="table-responsive">
                                <table class="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Player</th>
                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Role</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Team</th>
                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="player in players" :key="player">
                                            <td>
                                                <h6 class="mb-0 text-xs">{{ player.name }}</h6>
                                            </td>
                                            <td>
                                                <h6 class="mb-0 text-xs">{{ player.type }}</h6>
                                            </td>
                                            <td>
                                                <h6 class="mb-0 text-xs">{{ player.team }}</h6>
                                            </td>
                                            <td>
                                                <a class="btn btn-link text-info text-gradient px-3 mb-0" href="javascript:;" @click="adddata(player.name, player.type, player.team)">
                                                    <i class="fas fa-plus me-2" aria-hidden="true"></i>Add</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="modalclose('closeModal')" type="button" class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>

export default {
    props: ["index", "active", "data", "players"],
    data() {
        return {
            updateData: false,
        }
    },
    methods: {
        removedata(index) {
            this.data.data.splice(index, 1);
        },
        adddata(name, type, team) {
            this.data.data.push({
                name: name,
                type: type,
                team: team,
            });
        },
        modalclose(funtext) {
            this.$emit(funtext, this.data, this.index);
        },
        async formSubmit() {
            this.modalclose("closeModal");
        },
    },
}
</script>

<style>
</style>