<template>
  <div class="card mt-4">
    <div class="card-body p-3">
      <div class="row mb-2">
        <div class="col">
          <h5 class="mb-0">{{ subscription.planname }}</h5>
        </div>
        <div class="col text-right">
          <h5 v-if="subscription.offer" class="mb-0 red-text">
            <b>
              <del>₹{{ subscription.price }}</del>
            </b>
          </h5>
          <h5 v-else class="mb-0">
            <b>₹{{ subscription.price }}</b>
          </h5>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">Order ID : {{ subscription.orderId }}</div>
        <div v-if="subscription.offer" class="col-auto text-right">
          <h5 class="mb-0 green-text">
            <b>₹{{ subscription.offeramount }}</b>
          </h5>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col">
          <h6 class="mb-0">{{ timeright(subscription.startDate) }}</h6>
        </div>
        <div class="col-auto">
          <h6 v-if="subscription.refundAmt > 0 " class="mb-0 text-center red-text">Refunded</h6>
          <h6
            v-else-if="isSubscriptionActive(subscription.endDate)"
            class="mb-0 text-center green-text"
          >Active</h6>
          <h6 v-else class="mb-0 text-center grey-text">Expired</h6>
        </div>
        <div class="col">
          <h6 class="mb-0 text-right">{{ timeright(subscription.endDate) }}</h6>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <div class="progress-wrapper">
            <div class="progress">
              <div
                v-if="subscription.refundAmt > 0 "
                class="progress-bar bg-gradient-danger"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 100%;"
              ></div>
              <div
                v-else-if="isSubscriptionActive(subscription.endDate)"
                class="progress-bar bg-gradient-success"
                role="progressbar"
                :aria-valuenow="remaningsubscription(subscription.startDate, subscription.endDate)"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="{'width': remaningsubscription(subscription.startDate, subscription.endDate)+'%'}"
              ></div>
              <div
                v-else
                class="progress-bar bg-gradient-light"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 100%;"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4" v-if="subscription.refundAmt > 0">
        <p
          class="red-text text-center mb-0"
        >₹{{subscription.refundAmt }} refunded on {{timeright(subscription.endDate)}} {{subscription.refundtxt }}.</p>
      </div>
      <div class="row">
        <div v-if="isSubscriptionActive(subscription.endDate)" class="col">
          <button
            @click="refundsubscription()"
            type="button"
            class="btn w-100 mb-0 btn-outline-danger"
          >Refund</button>
        </div>
        <div class="col">
          <button
            @click="viewsubscription()"
            type="button"
            class="btn w-100 mb-0 btn-outline-info"
          >View</button>
        </div>
        <div v-if="isSubscriptionActive(subscription.endDate)" class="col">
          <button
            @click="deletesubscription()"
            type="button"
            class="btn w-100 mb-0 btn-outline-warning"
          >Delete</button>
        </div>
      </div>
    </div>
  </div>
  <SubscriptionViewModal
    :subscription="subscription"
    :active="activeview"
    @viewsubscription="viewsubscription"
  />
  <SubscriptionRefundModal
    :subscription="subscription"
    :active="activerefund"
    @refundsubscription="refundsubscription"
  />
</template>

<script>
import TimeRight from "../../timeright";

import database from "../../databasefuncation";

import SubscriptionViewModal from "../modals/subscription_view_modal.vue";
import SubscriptionRefundModal from "../modals/subscription_refund_modal.vue";

export default {
  components: { SubscriptionViewModal, SubscriptionRefundModal },
  props: ["subscription"],
  data() {
    return {
      activeview: false,
      activerefund: false,
    };
  },
  methods: {
    timeright(timeold) {
      var time = TimeRight.datemodifileryear(timeold);
      return time;
    },
    isSubscriptionActive(time) {
      var isactive = TimeRight.checksubscription(time);
      return isactive;
    },
    remaningsubscription(start, end) {
      var parsntage = TimeRight.remaningsubscription(start, end);
      return parsntage;
    },
    viewsubscription() {
      this.activeview = !this.activeview;
    },
    refundsubscription() {
      this.activerefund = !this.activerefund;
    },
    async deletesubscription() {
      await database.deletedoc("subscriptions", this.subscription.orderId);

      

    },
  },
};
</script>

<style>
</style>