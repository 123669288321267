<template>
  <AddEditNews :isnew="isnew" />
</template>

<script>
import AddEditNews from "../../components/news/Add-Edit-News.vue";

export default {
  components: { AddEditNews },
  data() {
    return {
      isnew: false,
    };
  },
};
</script>

<style>
</style>