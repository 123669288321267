<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-12 mb-3">
          <img class="img-fluid border-radius-lg preview" alt="Responsive image" :src="imageurl" />
        </div>
        <div class="col-4">
          <p class="m-0">ID</p>
          <h5>
            <b>{{ id }}</b>
          </h5>
        </div>
        <div class="col-4">
          <p class="m-0">Position</p>
          <h5>
            <b>{{ position }}</b>
          </h5>
        </div>
        <div class="col-4" style="text-align : left">
          <p class="m-0">Active</p>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="active"
              style="margin-left: auto; margin-right: 0; float: none; "
              v-on:input="changebannerstate()"
              v-bind:disabled="!action"
            />
          </div>
        </div>
        <div class="col-12">
          <p class="m-0">Data</p>
          <h5>
            <b>{{ data }}</b>
          </h5>
        </div>
      </div>
      
      <div class="actionbtn" v-if="action">
        <div class="row">
          <div class="col-6">
            <button
              type="button"
              class="btn bg-gradient-secondary w-100 mb-0 mt-3"
              @click="editbanner"
            >Edit</button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn bg-gradient-danger w-100 mb-0 mt-3"
              @click="deletebanner"
            >Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwlAlerts from "../../swlalerts.js";
import firebase from "firebase";

export default {
  props: [
    "id",
    "imageurl",
    "active",
    "data",
    "position",
    "action",
  ],
  data() {
    return {};
  },
  methods: {
    deletebanner() {
      SwlAlerts.swlloading();
      const db = firebase.firestore();
      const storage = firebase.storage();

      var user = firebase.auth().currentUser;

      firebase
        .storage()
        .ref("/banners/" + user.uid + "/" + this.id + ".jpg")
        .delete()
        .then(() => {
          db.collection("banners")
            .doc(this.id)
            .delete()
            .then(() => {
              SwlAlerts.swldone("Banner Deleted!");
            })
            .catch((error) => {
              console.log(error);
              SwlAlerts.swlerror(error.message);
            });
        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });
    },

    changebannerstate() {
      SwlAlerts.swlloading();

      const db = firebase.firestore();

      db.collection("banners")
        .doc(this.id)
        .update({
          active: !this.active,
        })
        .then(() => {
          SwlAlerts.swldone("Banner Status Changed!");
        })
        .catch((error) => {
          console.log(error);
          SwlAlerts.swlerror(error.message);
        });
    },

    editbanner() {
      this.$router.push({ path: `/banners/edit-banner/${this.id}` });
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
</style>