<template>
    <form @submit.prevent="addPerditinoSection">
        <div class="row">
            <div class="col d-flex align-items-center">
                <p class="h3" >Defaults</p>
            </div>
            <div class="col d-flex align-items-center justify-content-end">
                <select class="form-control mr-2" v-model="activeDefault" required style="margin-right: 0.5rem !important;">
                    <option :value="null">Select Section</option>
                    <option :value="defaultS.type" v-for="defaultS in finlterSection(defaultSections)" :key="defaultS">{{ defaultS.name }}</option>
                </select>
                <button type="submit" class="btn bg-gradient-success mb-0">Add</button>
            </div>
        </div>
    </form>
    <div class="defaultPrediction">
        <!-- <div class="defaultCard mt-3" v-for="(defaultnew, index) in defaults" :key="defaultnew"> -->

        <draggable v-model="defaults" group="defaults" :key="defaults" @start="drag = true" @end="drag = false" :move="changeData" item-key="id">
            <template #item="{ element, index }">
                <div class="defaultCard mt-3">
                    <div class="row">
                        <div class="col d-flex align-items-center">
                            <input class="form-control mb-3" v-model="element.name" required>
                            <!-- <p class="h5" contenteditable="true">{{ element.name }}</p> -->
                        </div>
                        <div class="col d-flex align-items-center justify-content-end">
                            <a class="btn btn-link text-info text-gradient px-3 mb-0" href="javascript:;" @click="editSection(index)"><i class="far fa-edit me-2" aria-hidden="true"></i>Edit</a>
                            <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;" @click="deleteSection(index)"><i class="far fa-trash-alt me-2"
                                    aria-hidden="true"></i>Delete</a>
                        </div>
                    </div>
                    <Preview v-if="element.type == 'preview'" :data="element.data" />
                    <PitchReport v-if="element.type == 'pitchReport'" :data="element.data" />
                    <WPS v-if="element.type == 'wps'" :data="element.data" />
                    <Probable v-if="element.type == 'probable'" :data="element.data" :teams="teams" />
                    <TeamComparison v-if="element.type == 'teamComparison'" :data="element.data" :teams="teams" />
                    <TopPicks v-if="element.type == 'topPicks'" :data="element.data" :teams="teams" />
                </div>
            </template>
        </draggable>
    </div>
    <PreviewModal :index="modalIndex" :active="previewModalvisible" :data="previewModalData" @closeModal="closeModal" />
    <PitchReportModal :index="modalIndex" :active="pitchReportModalvisible" :data="pitchReportModalData" @closeModal="closeModal" />
    <WPSModal :index="modalIndex" :active="WPSModalvisible" :data="WPSModalData" @closeModal="closeModal" />
    <ProbableModal :index="modalIndex" :active="ProbableModalvisible" :data="ProbableModalData" @closeModal="closeModal" :players="players" />
    <TeamComparisonModal :index="modalIndex" :active="teamComparisonModalvisible" :data="teamComparisonModalData" @closeModal="closeModal" :players="players" />
    <TopPicksModal :index="modalIndex" :active="topPicksModalvisible" :data="topPicksModalData" @closeModal="closeModal" :players="players" />
</template>

<script>

import draggable from "vuedraggable";

import Preview from "./default/preview.vue"
import PreviewModal from "./modals/previewModal.vue"


import PitchReport from "./default/pitchReport.vue"
import PitchReportModal from "./modals/pitchReportModal.vue"

import WPS from "./default/wps.vue"
import WPSModal from "./modals/wpsModal.vue"

import Probable from "./default/probable.vue"
import ProbableModal from "./modals/probableModal.vue"

import TeamComparison from "./default/teamComparison.vue"
import TeamComparisonModal from "./modals/teamComparisonModal.vue"

import TopPicks from "./default/topPicks.vue"
import TopPicksModal from "./modals/topPicksModal.vue"


export default {
    props: ["defaults", "sport", "players", "teams"],
    components: { draggable, Preview, PreviewModal, PitchReport, PitchReportModal, WPS, WPSModal, Probable, ProbableModal, TeamComparison, TeamComparisonModal, TopPicks, TopPicksModal },
    data() {
        return {
            activeDefault: null,
            defaultSections: [
                {
                    name: "Preview",
                    type: "preview",
                    sport: "any"
                },
                {
                    name: "Pitch Report",
                    type: "pitchReport",
                    sport: "cricket"
                },
                {
                    name: "Wicket by Pacers & Spinners",
                    type: "wps",
                    sport: "cricket"
                },
                {
                    name: "Probable Players",
                    type: "probable",
                    sport: "any"
                },
                {
                    name: "Team Comparison",
                    type: "teamComparison",
                    sport: "any"
                },
                {
                    name: "Top Picks",
                    type: "topPicks",
                    sport: "any"
                },
            ],
            //CurrentIndex
            modalIndex: null,
            // Preview Modals
            previewModalvisible: false,
            previewModalData: null,
            // Preview Modals
            pitchReportModalvisible: false,
            pitchReportModalData: null,
            // WPS Modals
            WPSModalvisible: false,
            WPSModalData: null,
            // Probable Modals
            ProbableModalvisible: false,
            ProbableModalData: null,
            // Probable Modals
            teamComparisonModalvisible: false,
            teamComparisonModalData: null,
            // Probable Modals
            topPicksModalvisible: false,
            topPicksModalData: null,
        }
    },
    async created() {

    },
    methods: {
        async addPerditinoSection() {
            if (this.activeDefault == "preview") {
                this.defaults.push({
                    name: "Preview",
                    type: "preview",
                    sport: "any",
                    data: "",
                });
            } else if (this.activeDefault == "pitchReport") {
                this.defaults.push({
                    name: "Pitch Report",
                    type: "pitchReport",
                    sport: "cricket",
                    data: {
                        pitchExtra: [],
                        extraInfo: [],
                        text: ""
                    },
                });
            } else if (this.activeDefault == "wps") {
                this.defaults.push({
                    name: "Wicket by Pacers & Spinners",
                    type: "wps",
                    sport: "cricket",
                    data: [],
                });
            } else if (this.activeDefault == "probable") {
                this.defaults.push({
                    name: "Probable Players",
                    type: "probable",
                    sport: "any",
                    data: [],
                });
            } else if (this.activeDefault == "teamComparison") {
                this.defaults.push({
                    name: "Team Comparison",
                    type: "teamComparison",
                    sport: "any",
                    data: {
                        lastFive: {
                            name: "Last 5 Matches",
                            teams: [{
                                name: this.teams[0].name,
                                color: this.teams[0].color,
                                data: ["-", "-", "-", "-", "-"]
                            }, {
                                name: this.teams[1].name,
                                color: this.teams[1].color,
                                data: ["-", "-", "-", "-", "-"]
                            }]
                        },
                        teamPerformance: {
                            name: "Performance of both teams on this ground",
                            teams: [{
                                name: this.teams[0].name,
                                color: this.teams[0].color,
                                total: 0,
                                win: 0,
                                loss: 0
                            }, {
                                name: this.teams[1].name,
                                color: this.teams[1].color,
                                total: 0,
                                win: 0,
                                loss: 0
                            }],
                        }
                    },
                });
            } else if (this.activeDefault == "topPicks") {
                this.defaults.push({
                    name: "Top Picks",
                    type: "topPicks",
                    sport: "any",
                    data: [],
                });
            }

            this.editSection(this.defaults.length - 1);
        },
        editSection(index) {
            this.modalIndex = index;
            if (this.defaults[index].type == "preview") {
                this.previewModalData = this.defaults[index];
                this.previewModalvisible = true;
            } else if (this.defaults[index].type == "pitchReport") {
                this.pitchReportModalData = this.defaults[index];
                this.pitchReportModalvisible = true;
            } else if (this.defaults[index].type == "wps") {
                this.WPSModalData = this.defaults[index];
                this.WPSModalvisible = true;
            } else if (this.defaults[index].type == "probable") {
                this.ProbableModalData = this.defaults[index];
                this.ProbableModalvisible = true;
            } else if (this.defaults[index].type == "teamComparison") {
                this.teamComparisonModalData = this.defaults[index];
                this.teamComparisonModalvisible = true;
            } else if (this.defaults[index].type == "topPicks") {
                this.topPicksModalData = this.defaults[index];
                this.topPicksModalvisible = true;
            }

        },
        closeModal(data, index) {
            if (data.type == "preview") {
                this.defaults[index] = data;
                this.previewModalvisible = false;
            } else if (data.type == "pitchReport") {
                this.defaults[index] = data;
                this.pitchReportModalvisible = false;
            } else if (data.type == "wps") {
                this.defaults[index] = data;
                this.WPSModalvisible = false;
            } else if (data.type == "probable") {
                this.defaults[index] = data;
                this.ProbableModalvisible = false;
            } else if (data.type == "teamComparison") {
                this.defaults[index] = data;
                this.teamComparisonModalvisible = false;
            } else if (data.type == "topPicks") {
                this.defaults[index] = data;
                this.topPicksModalvisible = false;
            }
            this.modalIndex = null;
        },
        deleteSection(index) {
            this.defaults.splice(index, 1);
        },

        finlterSection(array) {
            var newItems = [];
            array.forEach(element => {
                if (element.sport == "any" || element.sport == this.sport) {
                    newItems.push(element);
                }
            });
            return newItems;
        },

        changeData(){
            console.log(this.defaults);
        }
    }
}
</script>

<style>
</style>