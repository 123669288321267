<template>
  <div class="tshirt">
    <img class="base" src="@/assets/img/tshirt/base.png" />
    <div
      class="color"
      :style="{
        'background-color' : color,
        'mask-image': 'url(' + colormask + ')',
        'mask-size': '100% 100%'
    }"
    ></div>
    <img class="gray" src="@/assets/img/tshirt/gray.png" />
  </div>
</template>

<script>
import colormask from "../../assets/img/tshirt/color.png";
export default {
  props: ["color"],
  data() {
    return {
      colormask,
    };
  },
};
</script>

<style>
.tshirt {
  position: relative;
}
.tshirt img {
  max-width: 100%;
}
.tshirt .color,
.tshirt img.gray {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
}
</style>