<template>
  <nav class="navbar navbar-vertical mb-3 p-0">
    <ul class="navbar-nav">
      <li class="nav-item" v-for="sport in sports" :key="sport">
        <a
          href="javascript:void(0)"
          v-on:click="changetab(sport.id)"
          :class="{'nav-link' : true, active: (sport.id == activeport)}"
        >{{sport.name}}</a>
      </li>
    </ul>
  </nav>

  <div class="row">
    <div class="col-xl-4 mb-4" v-for="serie in series" :key="serie">
      <SeriesPreview
        :did="serie.did"
        :id="serie.id"
        :name="serie.name"
        :sport="serie.sport"
        :action="action"
      />
    </div>
    <div class="col-xl-12" style="text-align:center">
      <button class="btn bg-gradient-secondary" @click="getdata()">Load More</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

import SeriesPreview from "../../components/series/Series-Preview.vue";

export default {
  components: { SeriesPreview },
  data() {
    return {
      limit: 0,
      series: [],
      action: true,

      sports: [],
      activeport: "cricket",
    };
  },
  async created() {
    const db = firebase.firestore();

    var path = db.collection("sports").orderBy("position");

    this.sports = await database.getalldocs(path);

    this.getdata();
  },
  methods: {
    async getdata() {
      this.limit = this.limit + 50;

      const db = firebase.firestore();

      var path = db.collection("series").where("sport", "==", this.activeport).orderBy("name").limit(this.limit);

      path.limit(this.limit).onSnapshot((querySnapshot) => {
        var seriestemp = [];
        querySnapshot.forEach((doc) => {
          seriestemp.push(doc.data());
        });
        this.series = seriestemp;
      });
    },
    changetab(id) {
      this.limit = 0;
      this.activeport = id;
      this.getdata();
    },
  },
};
</script>

<style scoped>
.navbar {
  display: block;
  box-shadow: none;
}
.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-nav .nav-item {
  width: 100%;
}
.navbar-nav .nav-item .nav-link {
  text-align: center;
  display: block;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>