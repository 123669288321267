<template>
  <div class="row">
    <div class="col-xl-12 mb-3">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="updatenotification">
            <div class="row">
              <div class="col-xl-10">
                <h2>Category</h2>
              </div>

              <div class="col-xl-2" style="text-align: right;">
                <button
                  type="button"
                  class="btn bg-gradient-secondary w-100"
                  @click="addCategory"
                >Add Category</button>
              </div>
            </div>

            <div class="row" v-for="(category, index) in categores" :key="(category, index)">
              <div class="col-xl-8">
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Category Name"
                    v-model="category.name"
                    required
                  />
                </div>
              </div>
              <div class="col-xl-2" style="text-align: right;">
                <button
                  type="button"
                  class="btn bg-gradient-secondary w-100"
                  @click="addTag(index)"
                >Add Tag</button>
              </div>
              <div class="col-xl-2" style="text-align: right;">
                <button
                  type="button"
                  class="btn bg-gradient-danger w-100"
                  @click="deleteCat(index)"
                >Delete Category</button>
              </div>

              <div class="col-xl-4" v-for="(tag, tagindex) in category.tags" :key="(tag, tagindex)">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-xl-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Tag Name"
                        v-model="categores[index].tags[tagindex]"
                        required
                      />
                    </div>
                    <div class="col-xl-4">
                      <button
                      type="button"
                      class="btn bg-gradient-danger w-100"
                      @click="deletetag(index, tagindex)"
                    >Delete</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-2" style="text-align:right;">
                <button type="submit" class="btn bg-gradient-info mb-0 w-100">
                  <span>Update Notification</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  data() {
    return {
      categores: [],
    };
  },

  async created() {
    var db = firebase.firestore();

    var path = db.collection("manage_app").doc("notification");

    var notification = await database.getdoc(path);

    this.categores = notification.category;
  },

  methods: {
    updatenotification() {
      var object = {
        category: this.categores,
      };

      database.updatedoc(object, "manage_app", "notification");
    },

    addCategory() {
      this.categores.push({
        name: "",
        tags: [],
      });
    },

    addTag(index){
      this.categores[index].tags.push("");
    },


    deleteCat(index) {
      this.categores.splice(index, 1);
    },
    deletetag(index, tagindex) {
      this.categores[index].tags.splice(tagindex, 1);
    },
  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>