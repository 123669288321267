import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

import Home from '../views/main/Home.vue'

import GST from '../views/main/GST.vue'

import Sports from '../views/main/Sports.vue'
import AddSport from '../views/sports/Add-Sport.vue'
import AllSports from '../views/sports/All-Sports.vue'
import EditSport from '../views/sports/Edit-Sport.vue'

import News from '../views/main/News.vue'
import AddNews from '../views/news/Add-News.vue'
import AllNews from '../views/news/All-News.vue'
import EditNews from '../views/news/Edit-News.vue'

import Series from '../views/main/Series.vue'
import AddSeries from '../views/series/Add-Series.vue'
import AllSeries from '../views/series/All-Series.vue'
import EditSeries from '../views/series/Edit-Series.vue'

import Teams from '../views/main/Teams.vue'
import AddTeam from '../views/teams/Add-Team.vue'
import AllTeams from '../views/teams/All-Teams.vue'
import EditTeam from '../views/teams/Edit-Team.vue'

import Players from '../views/main/Players.vue'
import AddPlayer from '../views/players/Add-Player.vue'
import AllPlayers from '../views/players/All-Players.vue'
import EditPlayer from '../views/players/Edit-Player.vue'

import Banners from '../views/main/Banners.vue'
import AddBanner from '../views/banners/Add-Banner.vue'
import AllBanners from '../views/banners/All-Banners.vue'
import EditBanner from '../views/banners/Edit-Banner.vue'

import Matches from '../views/main/Matches.vue'
import AddMatch from '../views/matches/Add-Match.vue'
import AllMatches from '../views/matches/All-Matches.vue'
import EditMatch from '../views/matches/Edit-Match.vue'

import Sync from '../views/main/Sync.vue'
import SyncMatches from '../views/sync/Sync-Matches.vue'
import SyncPlayers from '../views/sync/Sync-Players.vue'
import SyncVerify from '../views/sync/Sync-Verify.vue'

import ManageApp from '../views/main/Manage-App.vue'
import TopUsers from '../views/manage-app/topUsers.vue'
import Subscription from '../views/manage-app/Subscription.vue'
import CountryState from '../views/manage-app/Country-State.vue'
import NotificationTag from '../views/manage-app/Notification-Tag.vue'
import PredictionProvider from '../views/manage-app/Prediction-Provider.vue'

import Prediction from '../views/main/Prediction.vue'
import AddPrediction from '../views/prediction/Add-Prediction.vue'
import AllPredictions from '../views/prediction/All-Predictions.vue'
import EditPrediction from '../views/prediction/Edit-Prediction.vue'

import Users from '../views/main/Users.vue'

import Platfrom from '../views/main/Platfrom.vue'

import firebase from 'firebase';

import SwlAlerts from '../swlalerts.js';

const routes = [{
    path: '/',
    name: 'main',
    component: Dashboard,
    redirect: '/home',
    meta: {
        authRequired: true,
    },
    children: [
        {
            path: 'home',
            name: 'Dashboard',
            component: Home,
        },
        {
            path: 'prediction',
            name: 'Prediction',
            component: Prediction,
            redirect: '/prediction/all-predictions',
            children: [{
                path: 'add-prediction',
                name: 'Add New Prediction',
                component: AddPrediction,
            },
            {
                path: 'all-predictions',
                name: 'View All Predictions',
                component: AllPredictions,
            },
            {
                path: 'edit-prediction/:id',
                name: 'Edit Prediction',
                component: EditPrediction,
            },
            ]
        },
        {
            path: 'gst',
            name: 'GST',
            component: GST,
        },
        {
            path: 'users',
            name: 'Users',
            component: Users,
        },
        {
            path: 'manageapp',
            name: 'Manage App',
            component: ManageApp,
            redirect: '/manageapp/subscription',
            children: [
                {
                    path: 'topusers',
                    name: 'Top Users',
                    component: TopUsers,
                }, {
                    path: 'subscription',
                    name: 'Subscription',
                    component: Subscription,
                }, {
                    path: 'countrystate',
                    name: 'Country State',
                    component: CountryState,
                }, {
                    path: 'notificationtag',
                    name: 'Notification Tag',
                    component: NotificationTag,
                }, {
                    path: 'predictionprovider',
                    name: 'Prediction Provider',
                    component: PredictionProvider,
                }
            ]
        },
        {
            path: 'sports',
            name: 'Sports',
            component: Sports,
            redirect: '/sports/all-sports',
            children: [{
                path: 'add-sport',
                name: 'Add New Sport',
                component: AddSport,
            }, {
                path: 'all-sports',
                name: 'View All Sports',
                component: AllSports,
            }, {
                path: 'edit-sport/:id',
                name: 'Edit Sport',
                component: EditSport,
            },]
        },
        {
            path: 'news',
            name: 'News',
            component: News,
            redirect: '/news/all-news',
            children: [{
                path: 'add-news',
                name: 'Add New News',
                component: AddNews,
            }, {
                path: 'all-news',
                name: 'View All News',
                component: AllNews,
            }, {
                path: 'edit-news/:id',
                name: 'Edit News',
                component: EditNews,
            },]
        },
        {
            path: 'series',
            name: 'Series',
            component: Series,
            redirect: '/series/all-series',
            children: [{
                path: 'add-series',
                name: 'Add New Series',
                component: AddSeries,
            }, {
                path: 'all-series',
                name: 'View All Series',
                component: AllSeries,
            }, {
                path: 'edit-series/:id',
                name: 'Edit Series',
                component: EditSeries,
            },],
        },
        {
            path: 'teams',
            name: 'Teams',
            component: Teams,
            redirect: '/teams/all-teams',
            children: [{
                path: 'add-team',
                name: 'Add New Team',
                component: AddTeam,
            }, {
                path: 'all-teams',
                name: 'View All Teams',
                component: AllTeams,
            }, {
                path: 'edit-team/:id',
                name: 'Edit Team',
                component: EditTeam,
            },],
        },
        {
            path: 'players',
            name: 'Players',
            component: Players,
            redirect: '/players/all-players',
            children: [{
                path: 'add-player',
                name: 'Add New Player',
                component: AddPlayer,
            }, {
                path: 'all-players',
                name: 'View All Players',
                component: AllPlayers,
            }, {
                path: 'edit-player/:id',
                name: 'Edit Player',
                component: EditPlayer,
            },],
        },
        {
            path: 'banners',
            name: 'Banners',
            component: Banners,
            redirect: '/banners/all-banners',
            children: [{
                path: 'add-banner',
                name: 'Add New Banner',
                component: AddBanner,
            },
            {
                path: 'all-banners',
                name: 'View All Banners',
                component: AllBanners,
            },
            {
                path: 'edit-banner/:id',
                name: 'Edit Banner',
                component: EditBanner,
            },
            ]
        },
        {
            path: 'matches',
            name: 'Matches',
            component: Matches,
            redirect: '/matches/all-matches',
            children: [{
                path: 'add-match',
                name: 'Add New Match',
                component: AddMatch,
            },
            {
                path: 'all-matches',
                name: 'View All Matches',
                component: AllMatches,
            },
            {
                path: 'edit-match/:id',
                name: 'Edit Match',
                component: EditMatch,
            },
            ]
        },
        {
            path: 'sync',
            name: 'Sync',
            component: Sync,
            redirect: '/sync/sync-matches',
            children: [{
                path: 'sync-matches',
                name: 'Sync Matches',
                component: SyncMatches,
            },
            {
                path: 'sync-players',
                name: 'Sync Players',
                component: SyncPlayers,
            },
            {
                path: 'sync-verify',
                name: 'Sync Verify',
                component: SyncVerify,
            },
            ]
        },
        {
            path: 'platfrom',
            name: 'platfrom',
            component: Platfrom,
        },
    ]
},
{
    path: '/login',
    name: 'Login',
    component: Login
},
{
    path: '/register',
    name: 'Register',
    component: Register
},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})





router.beforeEach(async (to, from, next) => {

    const requiresAuth = to.matched.some(record => record.meta.authRequired);

    if (requiresAuth) {

        firebase.auth().onAuthStateChanged(user => {
            console.log('onAuthStateChanged Invoked => ' + user);
        });

        const user = firebase.auth().currentUser;

        if (user) {

            next();

        } else {
            next({
                path: '/login',
            });
        }

    } else {
        next();
    }



    // if (to.matched.some(record => record.meta.authRequired)) {
    //     SwlAlerts.swlloading()
    //     const db = firebase.firestore();

    //     var currentuser = firebase.auth().currentUser;

    //     if (firebase.auth().currentUser) {

    //         var user = firebase.auth().currentUser

    //         db.collection("admin").doc(user.uid).get().then((doc) => {
    //             if (doc.exists) {

    //                 if (doc.data().verify) {
    //                     SwlAlerts.swlclose()
    //                     next();
    //                 } else {
    //                     SwlAlerts.swlerror('Contact your administrator to verify your account!')
    //                     next({
    //                         path: '/login',
    //                     });
    //                 }
    //             } else {
    //                 SwlAlerts.swlerror('Contact your administrator to verify your account!')
    //                 next({
    //                     path: '/login',
    //                 });
    //             }
    //         }).catch((error) => {
    //             console.log("Error getting document:", error);
    //         });
    //     } else {
    //         SwlAlerts.swlerror('You must be logged in to see this page')
    //         next({
    //             path: '/login',
    //         });
    //     }
    // } else {
    //     next();
    // }
});

export default router