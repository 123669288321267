<template>
  <AddEditBanner :isnew="isnew" />
</template>

<script>

import AddEditBanner from "../../components/banners/Add-Edit-Banner.vue"

export default {
  components: { AddEditBanner },
  data() {
    return {
      isnew: false,
    };
  },
};
</script>

<style>
</style>