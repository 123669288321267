<template>
  <div class="row mt-3">
    <div class="col-12 mb-3" v-for="series in seriess" :key="series">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-2">
              <p>ID</p>
              <h5>{{ series.id }}</h5>
            </div>
            <div class="col-9">
              <p>Name</p>
              <h5>{{ series.name }}</h5>
            </div>
            <div class="col-1">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  @change="senddata"
                  v-model="selectedseries"
                  :value="{id:series.id, name:series.name}"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12" style="text-align:center">
      <button class="btn bg-gradient-secondary" @click="getdata()">Load More</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  props: ["sport", "selectedseries"],
  data() {
    return {
      limit: 0,
      seriess: [],
    };
  },
  created() {
    this.getdata();
  },
  watch: {
    sport: function (newVal, oldVal) {
      this.getdata();
    },
  },
  methods: {
    async getdata() {
      this.limit = this.limit + 50;
      const db = firebase.firestore();

      var path = db
        .collection("series")
        .where("sport", "==", this.sport)
        .orderBy("name")
        .limit(this.limit);

      this.seriess = await database.getalldocs(path);
    },
    senddata() {
      this.$emit("senddata", this.selectedseries);
    },
  },
};
</script>

<style>
</style>