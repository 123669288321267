<template>
  <div class="card">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-3 mb-2">
          <img v-if="img == ''" src="../../assets/img/default-player-image.png" alt />
          <img v-else :src="img" alt />
        </div>
        <div class="col-9 mb-2">
          <h4 class="mb-0">{{ name }}</h4>
          <p class="mb-0">{{ sport }} - {{ type }}</p>
        </div>
        <div class="col-4">
          <p class="m-0">ID</p>
          <h5>
            <b>{{ id }}</b>
          </h5>
        </div>
        <div class="col-4">
          <p class="m-0">D11 ID</p>
          <h5>
            <b>{{ did }}</b>
          </h5>
        </div>
        <div class="col-4">
          <p class="m-0">Credits</p>
          <h5>
            <b>{{ credits }}</b>
          </h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12">
          <p class="m-0">Teams</p>
          <span
            v-for="team in teams"
            :key="team"
            class="badge bg-gradient-secondary"
            style="margin-right:3px"
          >{{ team.sortname }}</span>
        </div>
      </div>
      <div class="actionbtn" v-if="action">
        <div class="row">
          <div class="col-6">
            <button
              type="button"
              class="btn bg-gradient-secondary w-100 mb-0 mt-3"
              @click="editplayer"
            >Edit</button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn bg-gradient-danger w-100 mb-0 mt-3"
              @click="deleteplayer"
            >Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwlAlerts from "../../swlalerts.js";
import firebase from "firebase";

export default {
  props: ["credits", "did", "id", "img", "name", "sport", "type", "action", "teams"],
  data() {
    return {
      mainimg: "../../assets/img/default-player-image.png",
    };
  },
  methods: {
    deleteplayer() {
      SwlAlerts.swlloading();
      const db = firebase.firestore();

      db.collection("players")
        .doc(this.id.toString())
        .delete()
        .then(() => {
          SwlAlerts.swldone("Player Deleted!");
        })
        .catch((error) => {
          console.log(error);
          SwlAlerts.swlerror(error.message);
        });
    },

    editplayer() {
      this.$router.push({ path: `/players/edit-player/${this.id}` });
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
</style>