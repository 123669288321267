<template>
  <nav class="navbar navbar-vertical mb-3 p-0">
    <ul class="navbar-nav">
      <li class="nav-item" v-for="sport in sports" :key="sport">
        <a href="javascript:void(0)" v-on:click="changesporttab(sport.id)" :class="{ 'nav-link': true, active: (sport.id == activeport) }">{{ sport.name }}</a>
      </li>
    </ul>
  </nav>

  <nav class="navbar navbar-vertical mb-3 p-0">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a href="javascript:void(0)" v-on:click="changetab()" :class="{ 'nav-link': true, active: upcoming }">Upcoming</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0)" v-on:click="changetab()" :class="{ 'nav-link': true, active: !upcoming }">Completed</a>
      </li>
    </ul>
  </nav>

  <div class="row">
    <div class="col-xl-4 mb-4" v-for="prediction in predictions" :key="prediction">
      <PredictionPreview :prediction="prediction" :action="action" />
    </div>
  </div>

</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import sweetalert from "../../swlalerts";

import PredictionPreview from "../../components/prediction/Prediction-Preview.vue";

export default {
  components: { PredictionPreview },
  data() {
    return {
      predictions: [],
      action: true,
      upcoming: true,
      limit: 0,
      pageNo: 1,
      sports: [],
      activeport: "cricket",
    };
  },
  async created() {
    const db = firebase.firestore();

    var path = db.collection("sports").orderBy("position");

    this.sports = await database.getalldocs(path);

    this.getalldata();
  },
  methods: {
    changetab() {
      this.upcoming = !this.upcoming;
      this.getalldata();
    },
    changesporttab(id) {
      this.limit = 0;
      this.activeport = id;
      this.getalldata();
    },
    getalldata() {
      this.limit = 50;

      const db = firebase.firestore();
      var currentdate = firebase.firestore.Timestamp.now().toDate();

      var path = this.upcoming
        ? db
          .collection("prediction")
          .where("sport", "==", this.activeport)
          .where("time", ">", currentdate)
          .orderBy("time")
          .limit(this.limit)
        : db
          .collection("prediction")
          .where("sport", "==", this.activeport)
          .where("time", "<", currentdate)
          .orderBy("time")
          .limit(this.limit);

      path.onSnapshot((querySnapshot) => {
        var matchestemp = [];

        querySnapshot.forEach((doc) => {
          matchestemp.push(doc.data());
        });

        

        this.predictions = matchestemp;
      });
    },

  },
};
</script>


<style scoped>
.navbar {
  display: block;
  box-shadow: none;
}

.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-nav .nav-item {
  width: 100%;
}

.navbar-nav .nav-item .nav-link {
  text-align: center;
  display: block;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>