<template>
  <div class="row">
    <div class="col-xl-4 mb-3" v-for="user in topUsers" :key="user">
      <div class="card">
        <div class="card-body">
          <div class="row gx-4">
            <div class="col-9">
              <div class="row">
                <div class="col-auto">
                <div class="avatar avatar-xl position-relative">
                  <img
                    :src="user.photoURL"
                    alt="profile_image"
                    class="w-100 border-radius-lg shadow-sm"
                  />
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">{{ user.displayName}}</h5>
                </div>
              </div>
              </div>
            </div>
            <div class="col-3 coins">
              <img src="@/assets/img/coin.svg" alt />
              <h3 class="warning">{{ user.coins }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  data() {
    return {
      topUsers: [],
    };
  },
  async created() {
    await this.getdata();
  },
  methods: {
    async getdata() {
      var db = firebase.firestore();

      var path = db.collection("manage_app").doc("topUser");

      var data = await database.getdoc(path);

      this.topUsers = data.users;
    },
  },
};
</script>

<style scoped>
.coins {
  display: flex;
  align-items: center;
  justify-content: end;
}
.coins img {
  margin: 0 10px 0 0;
  width: 32px;
}
.coins h3 {
  margin: 0;
  color: #ff9501;
}
</style>