export default {

    remaningsubscription(start, end){

        const now = Date.now();

        var startnow = start.seconds * 1000;
        var endnow = end.seconds * 1000;

        var totaltime = endnow - startnow;

        var usedtime = now - startnow;

        var parsantage = (usedtime / totaltime) * 100;

        return parsantage;

    },

    checksubscription(timestemp) {

        const now = Date.now();

        var subscription = timestemp.seconds * 1000;

        if (now < subscription) {
            return true;
        } else {
            return false;
        }

    },

    datemodifileryear_dateobject(timestemp) {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        var adate = new Date(timestemp);

        var daydate = adate.getDate();

        var yeardate = adate.getFullYear();

        var daymonth = monthNames[adate.getMonth()];

        var hours = adate.getHours();
        var minutes = adate.getMinutes();

        var ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;

        hours = hours ? hours : 12;

        hours = hours < 10 ? '0' + hours : hours;

        minutes = minutes < 10 ? '0' + minutes : minutes;

        var strTime = hours + ':' + minutes + ampm;


        return `${daydate} ${daymonth} ${yeardate} ${strTime}`;
    },

    datemodifileryear(timestemp) {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        var seconds = timestemp.seconds;
        var adate = new Date(seconds * 1000);

        var daydate = adate.getDate();

        var yeardate = adate.getFullYear();

        var daymonth = monthNames[adate.getMonth()];

        var hours = adate.getHours();
        var minutes = adate.getMinutes();

        var ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;

        hours = hours ? hours : 12;

        hours = hours < 10 ? '0' + hours : hours;

        minutes = minutes < 10 ? '0' + minutes : minutes;

        var strTime = hours + ':' + minutes + ampm;


        return `${daydate} ${daymonth} ${yeardate} ${strTime}`;
    },


    datemodifiler(timestemp) {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        var seconds = timestemp.seconds;
        var adate = new Date(seconds * 1000);

        var daydate = adate.getDate();

        var daymonth = monthNames[adate.getMonth()];

        var hours = adate.getHours();
        var minutes = adate.getMinutes();

        var ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;

        hours = hours ? hours : 12;

        hours = hours < 10 ? '0' + hours : hours;

        minutes = minutes < 10 ? '0' + minutes : minutes;

        var strTime = hours + ':' + minutes + ampm;


        return `${daydate} ${daymonth} ${strTime}`;
    },

    datetimer(timestemp) {

        var seconds = timestemp.seconds;
        var countDownDate = new Date(seconds * 1000);

        var sendtext = "";

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        //sendtext = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

        if (days >= 1) {
            sendtext = days + " days";
        } else if (days < 1 && hours >= 1) {
            sendtext = hours + "h " + minutes + "m";
        } else if (days < 1 && hours < 1) {
            sendtext = minutes + "m " + seconds + "s";
        }

        if (distance < 0) {
            sendtext = "Match Started";
        }

        return sendtext;


    },

    getlocaldattime(timestemp) {

        var seconds = timestemp.seconds;
        var date = new Date(seconds * 1000);


        var
            ten = function (i) {
                return (i < 10 ? '0' : '') + i;
            },
            YYYY = date.getFullYear(),
            MM = ten(date.getMonth() + 1),
            DD = ten(date.getDate()),
            HH = ten(date.getHours()),
            II = ten(date.getMinutes()),
            SS = ten(date.getSeconds());
        return YYYY + '-' + MM + '-' + DD + 'T' +
            HH + ':' + II + ':' + SS;
    }


}