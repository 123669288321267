import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase'
import axios from 'axios'
import VueAxios from 'vue-axios'


// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBnrNmKWt2HmbmdjCWbrhf9Ceap2QVCl5M",
    authDomain: "tmpfbp.firebaseapp.com",
    projectId: "tmpfbp",
    storageBucket: "tmpfbp.appspot.com",
    messagingSenderId: "155885848032",
    appId: "1:155885848032:web:ce4dd723edb05fbc46d785",
    measurementId: "G-FGV92L6EDH"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const app = createApp(App)

app.use(firebase)
app.use(router)
app.use(VueAxios, axios)

app.mount('#app')