<template>
  <div class="row">
    <div class="col-xl-8">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="saveteam">
            <div class="row">
              <div class="col-xl-4">
                <label>ID</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Team Id"
                    v-model.number="id"
                    v-bind:disabled="!isnew"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Dream11 ID</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Dream11 Team Id"
                    v-model.number="did"
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Name</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Team Name"
                    v-model="name"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Sort Name</label>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Team Sort Name"
                    v-model="sortname"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Color</label>
                <div class="mb-3">
                  <input
                    style="height : 40px"
                    type="color"
                    class="form-control"
                    placeholder="Team Color"
                    v-model="color"
                    required
                  />
                </div>
              </div>

              <div class="col-xl-4">
                <label>Sport</label>
                <div class="mb-3">
                  <select class="form-control" v-model="sport" required>
                    <option :value="sport" v-for="sport in sports" :key="sport">{{ sport }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row" v-for="(series, index) in seriess" :key="(series, index)">
              <div class="col-xl-6">
                <div class="mb-2">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="Series Id"
                    v-model.number="series.id"
                    required
                  />
                </div>
              </div>
              <div class="col-xl-6">
                <div class="mb-3">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="Series Name"
                    v-model="series.name"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12">
                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save Team</span>
                  <span v-else>Update Team</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <TeamPreview
        :team="{
          id: id,
          did: did,
          name: name,
          sortname: sortname,
          color: color,
          sport: sport,
          seriess: seriess,
          platforms: platforms
        }"
        :action="action"
        :sports="sports"
      />
      <SeriesList
        :sport="sport"
        :selectedseries="seriess"
        @senddata="addseries($event)"
        :key="sport"
      />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

import TeamPreview from "./Team-Preview.vue";
import SeriesList from "./Series-List.vue";

export default {
  props: ["isnew"],
  components: { TeamPreview, SeriesList },
  data() {
    return {
      id: null,
      did: null,
      name: "",
      sortname: "",
      color: "#a9dd3a",
      sport: "",
      seriess: [],
      platforms: {},
      sports: [],
      action: false,
    };
  },
  async created() {
    const db = firebase.firestore();

    if (this.isnew) {
      var path = db.collection("teams").orderBy("id", "desc").limit(1);

      var alldocs = await database.getalldocs(path);

      if (alldocs.length == 0) {
        this.id = 0;
      } else {
        alldocs.forEach((doc) => {
          this.id = parseInt(doc.id) + 1;
        });
      }
    } else {
      this.id = this.$route.params.id;

      var path = db.collection("teams").doc(this.id);

      var team = await database.getdoc(path);

      this.did = team.did;
      this.name = team.name;
      this.sortname = team.sortname;
      this.color = team.color;
      this.sport = team.sport;
      this.seriess = team.seriess;
      this.platforms = team.platforms;
    }

    var path = db.collection("sports").orderBy("position");

    var allsports = await database.getalldocs(path);

    allsports.forEach((doc) => {
      this.sports.push(doc.id);
    });
  },
  methods: {
    addseries(data) {
      this.seriess = data;
    },

    async saveteam() {
      var object = {
        id: this.id,
        did: this.did,
        name: this.name,
        sortname: this.sortname,
        color: this.color,
        sport: this.sport,
        seriess: this.seriess,
        platforms: this.isnew ? { dream11: { id: this.did } } : this.platforms,
      };

      this.isnew
        ? await database.savedoc(object, "teams", this.id.toString())
        : await database.updatedoc(object, "teams", this.id.toString());
    },
  },
};
</script>

<style>
</style>