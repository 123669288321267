<template>
  <AddEditTeam :isnew="isnew" />
</template>

<script>

import AddEditTeam from "../../components/teams/Add-Edit-Team.vue"

export default {
  components: { AddEditTeam },
  data() {
    return {
      isnew: true,
    };
  },
};
</script>

<style>
</style>