<template>
  <div class="card mt-4">
    <div class="card-body p-3">
      <a
        class="btn bg-gradient-danger mb-0 w-100"
        @click="deleteuser"
        href="javascript:void(0)"
      >Delete User</a>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  props: ["uid"],
  methods: {
    deleteuser() {
      Swal.fire({
        title: "Do you want to Delete the User?",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.finalDelete();
        }
      });
    },
    async finalDelete() {
      var db = firebase.firestore();

      //get all notification
      var notificationPath = db
        .collection("notification")
        .where("uid", "==", this.uid);

      var notification = await database.getalldocswithID(notificationPath);

      for (const noti of notification) {
        await database.deletedoc("notification", noti.id);
      }

      //Get all transaction

      var transactionPath = db
        .collection("transaction")
        .where("uid", "==", this.uid);

      var transaction = await database.getalldocswithID(transactionPath);

      for (const txn of transaction) {
        await database.deletedoc("transaction", txn.id);
      }

      //Delete All History

      var historyPath = db
        .collection("users")
        .doc(this.uid)
        .collection("history");

      var history = await database.getalldocswithID(historyPath);

      for (const his of history) {
        await db
          .collection("users")
          .doc(this.uid)
          .collection("history")
          .doc(his.id)
          .delete();
      }

      //delete all subscription

      var subscriptionsPath = db
        .collection("subscriptions")
        .where("uid", "==", this.uid);

      var subscriptions = await database.getalldocswithID(subscriptionsPath);

      for (const sub of subscriptions) {
        await database.deletedoc("subscriptions", sub.id);
      }

      await database.deletedoc("users", this.uid);
    },
  },
};
</script>

<style>
</style>