<template>
  <div class="row">
    <div class="col-xl-4" v-for="(match, index) in matches" :key="(match, index)">
      <MatchCard :match="match" :index="index" :syncbtn="true" @synccheck="synccheck" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

import MatchCard from "../../components/sync/platfrom/match-card.vue";
export default {
  props: ["sport"],
  components: { MatchCard },
  data() {
    return {
      matches: [],
    };
  },
  async created() {
    const db = firebase.firestore();

    var currentdate = firebase.firestore.Timestamp.now().toDate();

    var path = db
      .collection("matches")
      .where("sport", "==", this.sport.id)
      .where("time", ">", currentdate)
      .orderBy("time");

    path.onSnapshot((querySnapshot) => {
      var matchestemp = [];

      querySnapshot.forEach((doc) => {
        matchestemp.push(doc.data());
      });

      matchestemp.sort(function (a, b) {
        return b.highlight - a.highlight;
      });

      this.matches = matchestemp;
    });

  },
  methods: {
    async synccheck(index) {

      var id = this.matches[index].id;

      await database.synccheck(id);
    },
  },
};
</script>

<style>
</style>