<template>
  <div class="card mt-4">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Transactions</h6>
        </div>
        <div class="col-6 d-flex align-items-center justify-content-end">
          <button
            @click="getmorehistory"
            type="button"
            class="btn bg-gradient-success btn-sm mb-0"
          >Load More</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card mt-4">
    <div class="card-body p-3">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Time</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Content</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Icon</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Credit</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Coin</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(transaction, index) in transactions" :key="(transaction, index)">
              <td>
                <span class="text-secondary font-weight-bold">{{ timeright(transaction.time) }}</span>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ transaction.title }}</span>
              </td>
              <td>
                <span
                  v-if="transaction.icon == 'accountCreate'"
                  class="text-secondary font-weight-bold"
                >
                  <i class="fas fa-user-plus"></i>
                </span>
                <span
                  v-else-if="transaction.icon == 'subBuy'"
                  class="text-secondary font-weight-bold"
                >
                  <i class="fas fa-rupee-sign"></i>
                </span>
                <span v-else class="text-secondary font-weight-bold">
                  <i class="fas fa-bell"></i>
                </span>
              </td>
              <td>
                <div class="form-check form-switch ps-0">
                  <input
                    class="form-check-input ms-auto"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    v-model="transaction.credit"
                    disabled
                  />
                </div>
              </td>
              <td>
                <span class="text-secondary font-weight-bold">{{ transaction.coin }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import TimeRight from "../../timeright";

export default {
  props: ["uid"],
  data() {
    return {
      limit: 10,
      transactions: [],
    };
  },
  async created() {
    this.gethistory();
  },
  methods: {
    async gethistory() {
      const db = firebase.firestore();
      var path = db
        .collection("transaction")
        .where("uid", "==", this.uid)
        .orderBy("time", "desc")
        .limit(this.limit);

      this.transactions = await database.getalldocs(path);
    },
    getmorehistory() {
      this.limit += 10;
      this.gethistory();
    },
    timeright(timeold) {
      var time = TimeRight.datemodifileryear(timeold);
      return time;
    },
  },
};
</script>

<style scoped>
.table td,
.table th {
  white-space: normal;
}
</style>