<template>
  <nav class="navbar navbar-vertical mb-3 p-0" v-if="activeport != null">
    <ul class="navbar-nav">
      <li class="nav-item" v-for="sport in sports" :key="sport">
        <a
          href="javascript:void(0)"
          v-on:click="changetab(sport)"
          :class="{'nav-link' : true, active: (sport == activeport)}"
        >{{sport.name}}</a>
      </li>
    </ul>
  </nav>

  <SyncPlayers :sport="activeport" :key="componentKey" v-if="activeport != null" />
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";

import SyncPlayers from "../../components/sync/Sync-Players.vue";

export default {
  components: { SyncPlayers },
  data() {
    return {
      sports: [],
      componentKey: 0,
      activeport: null,
    };
  },
  async created() {
    const db = firebase.firestore();

    var path = db.collection("sports").orderBy("position");

    this.sports = await database.getalldocs(path);

    this.activeport = this.sports[0];
    this.forceRerender();
  },
  methods: {
    changetab(datasport) {
      this.activeport = datasport;
      this.forceRerender();
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>


<style scoped>
.navbar {
  display: block;
  box-shadow: none;
}
.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-nav .nav-item {
  width: 100%;
}
.navbar-nav .nav-item .nav-link {
  text-align: center;
  display: block;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>