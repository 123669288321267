<template>
  <div
    :class="{'modal fade' : true, show: active}"
    :style="{'display': active ? 'block' : 'none'}"
    :id="subscription.orderId + '_refund'"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="subscription.orderId + '_refund'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="modal-title-default">Subscription Refund</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Tag</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Data</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Day</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.day }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Display Name</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.displayName }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Email</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.email }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">End Date</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ timeright(subscription.endDate) }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Offer</span>
                  </td>
                  <td>
                    <div class="form-check form-switch ps-0">
                      <input
                        class="form-check-input ms-auto"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        v-model="subscription.offer"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Offer Amount</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.offeramount }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Offer Name</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.offername }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Order ID</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.orderId }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Plan ID</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.planid }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Plan Name</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.planname }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Price</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.price }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Refund Amount</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.refundAmt }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Refund Text</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.refundtxt }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Start Date</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ timeright(subscription.startDate) }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">State</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.state }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Txn Amount</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.txnAmount }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Txn Date</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ timeright(subscription.txnDate) }}</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-secondary font-weight-bold">Txn ID</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">
                      <b>{{ subscription.txnId }}</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <form @submit.prevent="refundnow">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Refund ID"
                v-model="refundid"
                required
              />
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form-control"
                placeholder="Enter Refund Amount"
                v-model.number="amount"
                required
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Why User Refund"
                v-model="text"
                required
              />
            </div>
            <div class="alert alert-danger alert-dismissible fade show text-white" role="alert">
              <span class="alert-text">
                <strong>₹{{ amount }}</strong> Refunded on
                <strong>{{ timeright(date) }}</strong>
                {{ text }}.
              </span>
            </div>
            <button type="submit" class="btn bg-gradient-danger w-100 mb-0">Refund Now</button>
          </form>
        </div>
        <div class="modal-footer">
          <button
            @click="funselcator('refundsubscription')"
            type="button"
            class="btn btn-link ml-auto mb-0"
            data-bs-dismiss="modal"
          >Close</button>
        </div>
      </div>
    </div>
  </div>
  <div
    :class="{'modal-backdrop fade' : true, show: active}"
    :style="{'display': active ? 'block' : 'none'}"
  ></div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import TimeRight from "../../timeright";

export default {
  props: ["subscription", "active"],
  data() {
    return {
      refundid: "",
      amount: 0,
      text: "",
      date: Date.now(),
    };
  },
  created() {
    const now = Date.now();

    var startnow = this.subscription.startDate.seconds * 1000;
    var endnow = this.subscription.endDate.seconds * 1000;

    var total = endnow - startnow;

    var used = now - startnow;

    var parprice = this.subscription.offer
      ? this.subscription.offeramount / total
      : this.subscription.price / total;

    var usedprice = used * parprice;

    this.amount = Math.round(
      this.subscription.offer
        ? this.subscription.offeramount - usedprice
        : this.subscription.price - usedprice
    );
  },
  methods: {
    timeright(timedata) {
      var time = TimeRight.datemodifileryear_dateobject(timedata);
      return time;
    },
    funselcator(funtext) {
      this.$emit(funtext);
    },
    async refundnow() {

      const object = {
        refundAmt: this.amount,
        refundtxt: this.text,
        refundid: this.refundid,
        endDate : new Date(),
      };

      await database.updatedoc(
        object,
        "subscriptions",
        this.subscription.orderId.toString()
      );

      this.funselcator("refundsubscription");
    },
  },
};
</script>

<style>
</style>