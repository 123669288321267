<template>
  <AddEditSeries :isnew="isnew" />
</template>

<script>
import AddEditSeries from "../../components/series/Add-Edit-Series.vue";

export default {
  components: { AddEditSeries },
  data() {
    return {
      isnew: false,
    };
  },
};
</script>

<style>
</style>