<template>
  <div class="row">
    <div class="col-xl-12 mb-3">
      <div class="card">
        <div class="card-header pb-0">
          <h6>Match Info</h6>
        </div>
        <div class="card-body">
          <form @submit.prevent="savematch" v-if="loading != true">
            <div class="row">
              <div class="col-xl-4">
                <label>ID</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Match ID" v-model.number="match.id"
                    v-bind:disabled="!isnew" required />
                </div>
              </div>
              <div class="col-xl-4">
                <label>DID</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Match DID" v-model.number="match.did" required />
                </div>
              </div>
              <div class="col-xl-4">
                <label>Sport</label>
                <div class="mb-3">
                  <select class="form-control" v-model="match.sport" @change="selectedSports" required>
                    <option :value="sport.id" v-for="sport in sports" :key="sport">{{ sport.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-3">
                <label>Time</label>
                <div class="mb-3">
                  <input type="datetime-local" class="form-control" placeholder="Match Time" v-model="time" required />
                </div>
              </div>
              <div class="col-xl-3">
                <label>Highlight</label>
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="match.highlight" />
                  </div>
                </div>
              </div>
              <div class="col-xl-3">
                <label>Playing</label>
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="match.playing" disabled />
                  </div>
                </div>
              </div>
              <div class="col-xl-3">
                <label>Started</label>
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="match.started" disabled />
                  </div>
                </div>
              </div>
              <div class="col-xl-12">
                <h5>Series Data</h5>
              </div>
              <div class="col-xl-4">
                <label>ID</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Series ID" v-model.number="match.series.id"
                    required />
                </div>
              </div>
              <div class="col-xl-4">
                <label>DID</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Series ID" v-model.number="match.series.did"
                    disabled required />
                </div>
              </div>
              <div class="col-xl-4">
                <label>Name</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Series Name" v-model.number="match.series.name"
                    disabled required />
                </div>
              </div>
            </div>
            <div class="row" v-for="(team, index) in match.teams" :key="(team, index)">
              <div class="col-xl-12">
                <h5>Team {{ index }}</h5>
              </div>
              <div class="col-xl-2">
                <label>ID</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Team ID" v-model.number="team.id" disabled
                    required />
                </div>
              </div>
              <div class="col-xl-2">
                <label>DID</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Team DID" v-model.number="team.did" disabled
                    required />
                </div>
              </div>
              <div class="col-xl-3">
                <label>Name</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Team Name" v-model.number="team.name" disabled
                    required />
                </div>
              </div>
              <div class="col-xl-3">
                <label>Sort Name</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Team Sort Name" v-model.number="team.sortname"
                    disabled required />
                </div>
              </div>
              <div class="col-xl-2">
                <label>Color</label>
                <div class="mb-3">
                  <input style="height : 40px" type="color" class="form-control" placeholder="Team Color"
                    v-model.number="team.color" disabled required />
                </div>
              </div>
            </div>

            <div class="row" v-if="match.verify != null">
              <div class="col-xl-12">
                <h5>Platform Data</h5>
              </div>

              <div class="col-xl-4" v-if="match.verify.dream11 != null">
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    Dream11
                    <input class="form-check-input" type="checkbox" v-model="match.verify.dream11" />
                  </div>
                </div>
              </div>

              <div class="col-xl-4" v-if="match.verify.my11circle != null">
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    MyTeam11
                    <input class="form-check-input" type="checkbox" v-model="match.verify.my11circle" />
                  </div>
                </div>
              </div>

              <div class="col-xl-4" v-if="match.verify.myteam11 != null">
                <div class="mt-1 mb-3">
                  <div class="form-check form-switch">
                    My11Cricle
                    <input class="form-check-input" type="checkbox" v-model="match.verify.myteam11" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12">
                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save Match</span>
                  <span v-else>Update Match</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>



    <!-- <div class="col-xl-12 mb-3" v-if="isnew != true && loading != true">
      <div class="card" v-if="match.verify != null">
        <div class="card-header pb-0">
          <h6>Match Data</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Player</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Points</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Sel Rate</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Type</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Dream11 Type</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Credits</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Dream11 Cr</th>
                  <th
                    v-if="match.verify.myteam11 != null && match.verify.myteam11 != false"
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >MyTeam11 Cr</th>
                  <th
                    v-if="match.verify.my11circle != null && match.verify.my11circle != false"
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >My11Circle Cr</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Error</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(player, index) in matchData.players" :key="(player, index)">
                  <td>
                    <div class="d-flex">
                      <span class="tshirt">
                        <Tshirt
                          v-if="player.team == match.teams[0].sortname"
                          :color="match.teams[0].color"
                        />
                        <Tshirt v-else :color="match.teams[1].color" />
                      </span>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ player.name }}</h6>
                        <p
                          class="text-xs text-secondary mb-0"
                        >ID {{ player.id }} | DID {{ player.did }} | Team {{ player.team }}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="text-secondary text-xs font-weight-bold">{{ player.points }}</span>
                  </td>
                  <td>
                    <span class="text-secondary text-xs font-weight-bold">{{ player.selectionRate }}</span>
                  </td>
                  <td>
                    <select class="form-control input-sm" v-model="player.type">
                      <option value>Type</option>
                      <option
                        :value="type.sortname"
                        v-for="type in sport.types"
                        :key="type"
                      >{{ type.sortname }}</option>
                    </select>
                  </td>
                  <td>
                    <input
                      class="form-control input-sm"
                      type="text"
                      v-model.number="player.platforms.dream11.type"
                    />
                  </td>
                  <td>
                    <input
                      class="form-control input-sm"
                      type="text"
                      v-model.number="player.credits"
                    />
                  </td>
                  <td>
                    <input
                      class="form-control input-sm mr-1"
                      type="text"
                      v-model.number="player.platforms.dream11.credits"
                    />
                  </td>
                  <td v-if="match.verify.myteam11 != null && match.verify.myteam11 != false">
                    <input
                      class="form-control input-sm"
                      type="text"
                      v-model.number="player.platforms.myteam11.credits"
                    />
                  </td>
                  <td v-if="match.verify.my11circle != null  && match.verify.my11circle != false">
                    <input
                      class="form-control input-sm"
                      type="text"
                      v-model.number="player.platforms.my11circle.credits"
                    />
                  </td>
                  <td>
                    <span
                      v-if="player.credits != player.platforms.dream11.credits"
                      class="badge bg-gradient-danger"
                    >Credits Issue</span>
                    <span
                      v-if="player.type != player.platforms.dream11.type"
                      class="badge bg-gradient-danger"
                    >Type Issue</span>
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-icon btn-secondary"
                      @click="updatePlayer(player.id)"
                    >Update</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <button type="button" class="btn bg-gradient-info mb-0" @click="updateData">
                <span>Update Data</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-header pb-0">
          <h6>Match Data</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Player</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Points</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Sel Rate</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Type</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Dream11 Type</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Credits</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Dream11 Cr</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Error</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(player, index) in matchData.players" :key="(player, index)">
                  <td>
                    <div class="d-flex">
                      <span class="tshirt">
                        <Tshirt
                          v-if="player.team == match.teams[0].sortname"
                          :color="match.teams[0].color"
                        />
                        <Tshirt v-else :color="match.teams[1].color" />
                      </span>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ player.name }}</h6>
                        <p
                          class="text-xs text-secondary mb-0"
                        >ID {{ player.id }} | DID {{ player.did }} | Team {{ player.team }}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="text-secondary text-xs font-weight-bold">{{ player.points }}</span>
                  </td>
                  <td>
                    <span class="text-secondary text-xs font-weight-bold">{{ player.selectionRate }}</span>
                  </td>
                  <td>
                    <select class="form-control input-sm" v-model="player.type">
                      <option value>Type</option>
                      <option
                        :value="type.sortname"
                        v-for="type in sport.types"
                        :key="type"
                      >{{ type.sortname }}</option>
                    </select>
                  </td>
                  <td>
                    <input
                      class="form-control input-sm"
                      type="text"
                      v-model.number="player.platforms.dream11.type"
                    />
                  </td>
                  <td>
                    <input
                      class="form-control input-sm"
                      type="text"
                      v-model.number="player.credits"
                    />
                  </td>
                  <td>
                    <input
                      class="form-control input-sm mr-1"
                      type="text"
                      v-model.number="player.platforms.dream11.credits"
                    />
                  </td>
                  <td>
                    <span
                      v-if="player.credits != player.platforms.dream11.credits"
                      class="badge bg-gradient-danger"
                    >Credits Issue</span>
                    <span
                      v-if="player.type != player.platforms.dream11.type"
                      class="badge bg-gradient-danger"
                    >Type Issue</span>
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-icon btn-secondary"
                      @click="updatePlayer(player.id)"
                    >Update</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <button type="button" class="btn bg-gradient-info mb-0" @click="updateData">
                <span>Update Data</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-xl-4">
      <SeriesList
        :sport="sport"
        :selectedseries="{id:series.id, did:series.did,  name:series.name, platforms:series.platforms}"
        @senddata="addseries($event)"
      />
      <TeamList
        v-if="series.id !== undefined"
        :sport="sport"
        :seriesid="series.id"
        :seriesname="series.name"
        :selectedteams="teams"
        @senddata="addteam($event)"
      />
    </div>-->
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import SwlAlerts from "../../swlalerts.js";
import TimeRight from "../../timeright";

import Tshirt from "../../components/teams/Tshirt.vue";

import SeriesList from "../../components/matches/Series-List.vue";
import TeamList from "../../components/matches/Team-List.vue";

export default {
  props: ["isnew"],
  components: { SeriesList, TeamList, Tshirt },
  data() {
    return {
      id: null,
      match: {
        id: null,
      },
      matchData: null,
      oldMatchData: null,
      time: null,
      sports: [],
      sport: null,
      action: false,
      loading: true,
    };
  },
  async created() {
    const db = firebase.firestore();

    if (this.isnew) {
      var path = db.collection("matches").orderBy("id", "desc").limit(1);

      var alldocs = await database.getalldocs(path);

      if (alldocs.length == 0) {
        this.match.id = 0;
      } else {
        alldocs.forEach((doc) => {
          this.match.id = parseInt(doc.id) + 1;
        });
      }
    } else {
      this.id = this.$route.params.id;

      var path = db.collection("matches").doc(this.id);
      var matchDatapath = db.collection("matches_data").doc(this.id);

      this.match = await database.getdoc(path);
      this.matchData = await database.getdoc(matchDatapath);

      this.oldMatchData = JSON.parse(JSON.stringify(this.matchData));

      this.time = TimeRight.getlocaldattime(this.match.time);
    }

    var path = db.collection("sports").orderBy("position");

    this.sports = await database.getalldocs(path);

    await this.selectedSports();

    this.loading = false;
  },
  methods: {
    async selectedSports() {
      for (const sport of this.sports) {
        if (sport.id == this.match.sport) {
          this.sport = sport;
          break;
        }
      }
    },

    addseries(data) {
      this.series = data;
    },

    addteam(data) {
      this.teams = data;
    },

    async updatePlayer(playerID) {
      const db = firebase.firestore();

      for (const player of this.matchData.players) {
        if (player.id == playerID) {
          var playerPath = db.collection("players").doc(player.id.toString());

          var playerData = await database.getdoc(playerPath);

          playerData.credits = player.credits;
          playerData.platforms.dream11.credits =
            player.platforms.dream11.credits;

          playerData.type = player.type;
          playerData.platforms.dream11.type = player.platforms.dream11.type;

          if (this.match.verify != null) {
            if (
              this.match.verify.myteam11 != null &&
              this.match.verify.myteam11 != false
            ) {
              playerData.platforms.myteam11.credits =
                player.platforms.myteam11.credits;
            }

            if (
              this.match.verify.my11circle != null &&
              this.match.verify.my11circle != false
            ) {
              playerData.platforms.my11circle.credits =
                player.platforms.my11circle.credits;
            }
          }

          await database.updatedoc(playerData, "players", player.id.toString());
        }
      }
      await database.updatedoc(this.matchData, "matches_data", this.id);
    },

    async updateData() {
      const db = firebase.firestore();

      var index = 0;

      for (const player of this.matchData.players) {
        if (this.match.verify != null) {
          if (
            this.oldMatchData.players[index].credits != player.credits ||
            this.oldMatchData.players[index].type != player.type
          ) {
            var playerPath = db.collection("players").doc(player.id.toString());

            var playerData = await database.getdoc(playerPath);

            playerData.credits = player.credits;
            playerData.platforms.dream11.credits =
              player.platforms.dream11.credits;

            playerData.type = player.type;
            playerData.platforms.dream11.type = player.platforms.dream11.type;

            if (
              match.verify.myteam11 != null &&
              match.verify.myteam11 != false
            ) {
              playerData.platforms.myteam11.credits =
                player.platforms.myteam11.credits;
            }

            if (
              match.verify.my11circle != null &&
              match.verify.my11circle != false
            ) {
              playerData.platforms.my11circle.credits =
                player.platforms.my11circle.credits;
            }

            await database.updatedoc(
              playerData,
              "players",
              player.id.toString()
            );
          }
        } else {
          if (
            this.oldMatchData.players[index].credits != player.credits ||
            this.oldMatchData.players[index].type != player.type
          ) {
            var playerPath = db.collection("players").doc(player.id.toString());

            var playerData = await database.getdoc(playerPath);

            playerData.credits = player.credits;
            playerData.platforms.dream11.credits =
              player.platforms.dream11.credits;

            playerData.type = player.type;
            playerData.platforms.dream11.type = player.platforms.dream11.type;

            await database.updatedoc(
              playerData,
              "players",
              player.id.toString()
            );
          }
        }

        index++;
      }

      await database.updatedoc(this.matchData, "matches_data", this.id);
    },

    async savematch() {
      this.match.time = new Date(this.time);

      this.isnew
        ? await database.savedoc(
          this.match,
          "matches",
          this.match.id.toString()
        )
        : await database.updatedoc(
          this.match,
          "matches",
          this.match.id.toString()
        );
    },
  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}

.tshirt {
  max-width: 100px;
  margin-right: 10px;
}
</style>