<template>
  <section class="min-vh-100 mb-8">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">Welcome!</h1>
            <p class="text-lead text-white">
              Use these awesome forms to login or create new account in your
              project for free.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Register with</h5>
            </div>
            <div class="card-body">
              <form role="form text-left" @submit.prevent="register">
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    aria-label="Name"
                    aria-describedby="email-addon"
                    v-model="name"
                    required
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    aria-label="Email"
                    aria-describedby="email-addon"
                    v-model="email"
                    required
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="password-addon"
                    v-model="password"
                  />
                </div>
                <div class="text-center">
                  <button type="submit" class="btn bg-gradient-dark w-100 my-4 mb-2">Sign up</button>
                </div>
                <p class="text-sm mt-3 mb-0">
                  Already have an account?
                  <router-link class="text-dark font-weight-bolder" to="/Login">Login</router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import backgroundUrl from "../assets/img/curved-images/curved14.jpg";

import firebase from "firebase";

import SwlAlerts from "../swlalerts.js";

export default {
  data() {
    return {
      backgroundUrl,
      name: "",
      email: "",
      password: "",
    };
  },
  methods: {
    register() {
      SwlAlerts.swlloading();
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((user) => {

          const db = firebase.firestore();

          var userid = user.user.uid;

          db.collection("admin")
            .doc(userid)
            .set({
              name: this.name,
              email: this.email,
              role: "admin",
            })
            .then(() => {
              SwlAlerts.swldone("Successfully registered! Please login.");
              this.$router.push("/Login");
            })
            .catch((error) =>{
              SwlAlerts.swlerror(error.message);
            }) 
        })
        .catch((error) => {
          SwlAlerts.swlerror(error.message);
        });
    },
  },
};
</script>