<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }" :id="add_other_prediction" tabindex="-1" role="dialog" :aria-labelledby="add_other_prediction"
        aria-hidden="true">
        <div v-if="active" class="modal-dialog modal- modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">{{ data.name }}</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <h6>Probable PLayers</h6>
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Player</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Rating</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder">C</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder">VC</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Select</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(player, index) in data.data" :key="player">
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0">{{ player.name }}</p>
                                            <p class="text-xs text-secondary mb-0" style="white-space: nowrap;">Type: <b>{{ player.type }}</b> | Team: <b>{{ player.team }}</b> | Points: <b>{{ player.points }}</b> | Credits: <b>{{ player.credits }}</b></p>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" v-model.number="player.rating">
                                        </td>
                                        <td>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" v-model="player.c" style="margin:0 ;">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" v-model="player.vc" style="margin:0 ;">
                                            </div>
                                        </td>
                                        <td>
                                            <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;" @click="removedata(index)"><i class="far fa-trash-alt me-2"
                                                    aria-hidden="true"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-6">
                            <h6>Total Players</h6>
                            <div class="table-responsive">
                                <table class="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Player</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Points</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder">credits</th>
                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="player in players" :key="player">
                                            <td>
                                                <p class="text-xs font-weight-bold mb-0">{{ player.name }}</p>
                                            <p class="text-xs text-secondary mb-0" style="white-space: nowrap;">Type: <b>{{ player.type }}</b> | Team: <b>{{ player.team }}</b></p>
                                            </td>
                                            <td>
                                                <h6 class="mb-0 text-xs">{{ player.points }}</h6>
                                            </td>
                                            <td>
                                                <h6 class="mb-0 text-xs">{{ player.credits }}</h6>
                                            </td>
                                            <td>
                                                <a class="btn btn-link text-info text-gradient px-3 mb-0" href="javascript:;"
                                                    @click="adddata(player)">
                                                    <i class="fas fa-plus me-2" aria-hidden="true"></i>Add</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="modalclose('closeModal')" type="button" class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>
import { LightEditor, LightEditorPlugin } from "@hannanmiah/light-editor"

import "@hannanmiah/light-editor/dist/style.css"

export default {
    props: ["index", "active", "data", "players"],
    components: { LightEditor },
    methods: {
        removedata(index) {
            this.data.data.splice(index, 1);
        },
        adddata(player) {
            player.rating = 0;
            player.c = false;
            player.vc = false;
            this.data.data.push(player);
        },
        modalclose(funtext) {
            this.$emit(funtext, this.data, this.index);
        },
        async formSubmit() {
            this.modalclose("closeModal");
        },
    },
}
</script>

<style>
</style>