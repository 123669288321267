<template>
  <div class="row">
    <div class="col-xl-4 mb-4" v-for="sport in sports" :key="sport">
      <SportsPreview
        :id="sport.id"
        :name="sport.name"
        :color="sport.color"
        :status="sport.status"
        :total="sport.total"
        :min="sport.min"
        :max="sport.max"
        :types="sport.types"
        :position="sport.position"
        :platforms="sport.platforms"
        :cc="sport.cc"
        :vc="sport.vc"
        :action="action"
      />
    </div>
  </div>
</template>

<script>
import SportsPreview from "../../components/sports/Sports-Preview.vue";

import firebase from "firebase";
import database from "../../databasefuncation";

export default {
  components: { SportsPreview },
  data() {
    return {
      sports: [],
      action: true,
    };
  },
  async created() {
    var db = firebase.firestore();
    var path = db.collection("sports").orderBy("position");
    this.sports = await database.getalldocs(path);
  },
};
</script>