<template>
  <div class="card mb-2" v-for="player in data" :key="player">
    <div class="d-flex card-body p-3 justify-content-between align-items-center">
      <div class="d-flex">
        <span class="tshirt">
          <Tshirt v-if="player.team == teams[0].sortname" :color="teams[0].color" />
          <Tshirt v-else :color="teams[1].color" />
        </span>
        <div class="d-flex flex-column justify-content-center">
          <h6 class="mb-0 text-sm">{{ player.name }}</h6>
          <p class="text-xs text-secondary mb-0">Team {{ player.team }} | Type {{ player.type }} | PTS {{ player.points }} | Credits {{ player.credits }}</p>
        </div>
      </div>
      <div class="d-flex playerRight gap-2">
        <div class="rating">
          <span class="badge bg-gradient-success">Rating: <b>{{ player.rating }}</b></span>
        </div>
        <div class="c">
          <span v-if="player.c" class="badge bg-gradient-dark">C</span>
          <span v-else class="badge bg-gradient-light">C</span>
        </div>
        <div class="VC">
          <span v-if="player.vc" class="badge bg-gradient-dark">VC</span>
          <span v-else class="badge bg-gradient-light">VC</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tshirt from "../../../components/teams/Tshirt.vue";

export default {
  components: { Tshirt },
  props: ["data", "teams"],
}
</script>



<style scoped>
.tshirt {
  max-width: 100px;
  margin-right: 10px;
}
</style>