<template>
  <div class="card mt-4 mb-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <h6 class="mb-0">User Info</h6>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <ul class="list-group">
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">uid:</strong>
          {{ user.uid }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Email:</strong>
          {{ user.email }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Mobile:</strong>
          {{ user.mobile }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Refer Code:</strong>
          {{ user.referCode }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Referd Code:</strong>
          {{ user.referdCode }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">DOB:</strong>
          {{ user.dob }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Gender:</strong>
          {{ user.gender }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Address:</strong>
          {{ user.address }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">City:</strong>
          {{ user.city }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">State:</strong>
          {{ user.state }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Pincode:</strong>
          {{ user.pincode }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Register Date:</strong>
          {{ timeright(user.time) }}
        </li>
      </ul>
    </div>
  </div>
  <div class="card mt-4" v-if="user.subscription">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <h6 class="mb-0">Subscription</h6>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="form-check form-switch ps-0">
        <input
          class="form-check-input ms-auto"
          type="checkbox"
          id="flexSwitchCheckDefault"
          v-model="user.subscription"
          disabled
        />
        <label
          class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
          for="flexSwitchCheckDefault"
        >Subscription Active</label>
      </div>
      <ul class="list-group">
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Subscription End:</strong>
          {{ timeright(user.subscriptionEnd) }}
        </li>
        <li class="list-group-item border-0 ps-0 text-sm">
          <strong class="text-dark">Timer End:</strong>
          {{ timer(user.subscriptionEnd) }}
        </li>
      </ul>
    </div>
  </div>
  <div class="card mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <h6 class="mb-0">Notification</h6>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <span
        v-for="notification in user.notification"
        :key="notification"
        class="badge badge-secondary"
        style="margin-right:5px"
      >{{notification}}</span>
    </div>
  </div>
  <DeleteUser :key="user" :uid="user.uid" />
</template>

<script>
import TimeRight from "../../timeright";

import DeleteUser from "./deleteuser.vue";

export default {
  props: ["user"],
  components: { DeleteUser },
  methods: {
    timeright(timeold) {
      var time = TimeRight.datemodifileryear(timeold);
      return time;
    },
    timer(timeold) {
      var time = TimeRight.datetimer(timeold);
      return time;
    },
  },
};
</script>

<style scoped>
.badge-secondary {
  color: #5974a2;
  background-color: #e4e8ed;
}
</style>