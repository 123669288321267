<template>
  <div class="row">
    <div class="col-xl-8 mb-4">
      <div class="card">
        <div class="card-body p-3">
          <form @submit.prevent="savenews">
            <div class="row mb-1">

              <div class="col-xl-12">
                <label>Title</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Title" v-model="title" required />
                </div>
              </div>

              <div class="col-xl-12">
                <label>Hero Image URL</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Image URL" v-model="image" required />
                </div>
              </div>

              <div class="col-xl-12">
                <label>Post URL</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Post URL" v-model="url" required />
                </div>
              </div>

              <div class="col-xl-6">
                <label>Source Name</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Source Name" v-model="source.name" required />
                </div>
              </div>

              <div class="col-xl-6">
                <label>Source URL</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Source URL" v-model="source.url" required />
                </div>
              </div>

              <div class="col-xl-12">
                <label>Content</label>
                <div class="mb-3">
                  <textarea class="form-control" id="" rows="3" v-model="content" required></textarea>
                </div>
              </div>

              <div class="col-xl-12">
                <label>Description</label>
                <div class="mb-3">
                  <textarea class="form-control" id="" rows="3" v-model="description" required></textarea>
                </div>
              </div>

              <div class="col-xl-12">
                <label>Match ID</label>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Match ID" v-model="matchID"  />
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-xl-12" style="text-align:right;">
                <div class="button-group"></div>
                <button type="submit" class="btn bg-gradient-info mb-0">
                  <span v-if="isnew">Save News</span>
                  <span v-else>Update News</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4">

      <NewsPreview :id="id" :content="content" :description="description" :image="image" :publishedAt="publishedAt"
        :source="source" :time="time" :title="title" :url="url" :action="action" />

    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import NewsPreview from "./News-Preview.vue";


export default {
  props: ["isnew"],
  components: { NewsPreview },
  data() {
    return {
      id: "",
      content: "",
      description: "",
      image: "",
      publishedAt: new Date().toISOString(),
      source: {},
      time: firebase.firestore.FieldValue.serverTimestamp(),
      title: "",
      url: "",
      matchID : "",
      action: false,
    };
  },
  async created() {
    var db = firebase.firestore();
    var path = db.collection("platforms").orderBy("position");
    this.platforms = await database.getalldocs(path);

    console.log(this.isnew);
    if (!this.isnew) {
      this.id = this.$route.params.id;

      console.log(this.id);

      var path = db.collection("news").doc(this.id);

      var news = await database.getdoc(path);

      console.log(news);

      this.id = news.docid
      this.content = news.content
      this.description = news.description
      this.image = news.image
      this.publishedAt = news.publishedAt
      this.source = news.source
      this.time = news.time
      this.title = news.title
      this.url = news.url
      this.matchID = news.matchID

    }
  },
  methods: {

    savenews() {
      var object = {
        content: this.content,
        description: this.description,
        image: this.image,
        publishedAt: this.publishedAt,
        source: this.source,
        time: this.time,
        title: this.title,
        url: this.url,
        matchID: this.matchID,
      };

      if (this.isnew) {
        database.savedocwithoutdoc(object, "news");
      } else {
        database.updatedoc(object, "news", this.id);
      }
    },

  },
};
</script>

<style scoped>
.form-switch .form-check-input,
.form-check .form-check-input {
  margin-left: 0 !important;
  float: none;
}
</style>