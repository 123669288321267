<template>
  <AddEditPlayer :isnew="isnew" />
</template>

<script>

import AddEditPlayer from "../../components/players/Add-Edit-Player.vue"

export default {
  components: { AddEditPlayer },
  data() {
    return {
      isnew: false,
    };
  },
};
</script>

<style>
</style>