<template>
  <div class="card" style="overflow: hidden;">
    <div class="card-header">
      <h4 class="mb-0 text-center"><span :style="{ 'color': teams[0].color }">{{ teams[0].sortname }}</span> vs <span :style="{ 'color': teams[1].color }">{{ teams[1].sortname }}</span></h4>
    </div>
    <div class="card-body preview" :style="{   backgroundImage: `url(${backgroundUrl})`, backgroundColor : 'rgb(125, 206, 16)' }">
      <div class="row">
        <div class="col-6">
          <div class="d-flex justify-content-between" v-for="player in finlterSection(data, teams[0].sortname)" :key="player">
            <h4 class="mb-0 text-lg">{{ player.type }}</h4>
            <h4 class="mb-0 text-lg"><b>{{ player.name }}</b></h4>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex justify-content-between" v-for="player in finlterSection(data, teams[1].sortname)" :key="player">
            <h4 class="mb-0 text-lg"><b>{{ player.name }}</b></h4>
            <h4 class="mb-0 text-lg">{{ player.type }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backgroundUrl from "../../../assets/img/previewbg.png";

export default {
  props: ["teams", "data"],
  data() {
    return {
      backgroundUrl,
    };
  },
  created() {
    // console.log(this.data);
    // console.log(this.teams);
  },
  methods: {
    finlterSection(array, team) {
      var newItems = [];
      array.forEach(element => {
        if (element.team == team) {
          newItems.push(element);
        }
      });
      return newItems;
    }
  }
}
</script>

<style scoped>
h4.text-lg{
  color: #fff;
}
</style>