<template>
  <nav class="navbar navbar-vertical mb-3 p-0">
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/sports/all-sports">All Sports</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/sports/add-sport">Add Sport</router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link disabled"
          active-class="active"
          :to="'/sports/edit-sport/' + id"
        >Edit Sport</router-link>
      </li>
    </ul>
  </nav>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      id: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.name == "Edit Sport") {
        this.id = to.params.id
      }
    },
  },
};
</script>


<style scoped>
.navbar {
  display: block;
  box-shadow: none;
}
.navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-nav .nav-item {
  width: 100%;
}
.navbar-nav .nav-item .nav-link {
  text-align: center;
  display: block;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>