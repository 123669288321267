<template>
  <div class="card mt-4">
    <div class="card-body p-3">
      <div class="row">
        <div class="col d-flex align-items-center">
          <h6 class="mb-0">Subscription</h6>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <button
            @click="checksubscription"
            type="button"
            class="btn bg-gradient-info btn-sm mb-0 me-2"
          >Check Subscription</button>
          <button
            @click="addsubscription"
            type="button"
            class="btn bg-gradient-success btn-sm mb-0"
          >Active New Subscription</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      v-for="(subscription, index) in subscriptions"
      :key="(subscription, index)"
      class="col-md-6"
    >
      <SubscriptionView :subscription="subscription" />
    </div>
  </div>

  <SubscriptionAddModal
    :key="user"
    :user="user"
    :active="addsubscriptionmodal"
    @addsubscription="addsubscription"
  />
  <SubscriptionCheckModal
    :key="user"
    :user="user"
    :active="checksubscriptionmodal"
    @checksubscription="checksubscription"
  />
</template>

<script>
import firebase from "firebase";
import database from "../../databasefuncation";
import TimeRight from "../../timeright";
import SubscriptionView from "./subscription_view.vue";
import SubscriptionAddModal from "../modals/subscription_add_modal.vue";
import SubscriptionCheckModal from "../modals/subscription_check_modal.vue";

export default {
  components: { SubscriptionView, SubscriptionAddModal, SubscriptionCheckModal },

  props: ["subscription", "uid", "user"],

  data() {
    return {
      limit: 10,
      subscriptions: [],
      addsubscriptionmodal: false,
      checksubscriptionmodal: false,
    };
  },
  async created() {
    this.getsubscription();
  },
  methods: {
    async getsubscription() {
      const db = firebase.firestore();
      var path = db
        .collection("subscriptions")
        .where("uid", "==", this.uid)
        .orderBy("endDate", "desc")
        .limit(this.limit);

      path.onSnapshot((querySnapshot) => {
        var data = [];

        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });

        this.subscriptions = data;
      });
    },

    getmoresubscription() {
      this.limit += 10;
      this.getsubscription();
    },
    timeright(timeold) {
      var time = TimeRight.datemodifileryear(timeold);
      return time;
    },
    isSubscriptionActive(time) {
      var isactive = TimeRight.checksubscription(time);
      return isactive;
    },
    remaningsubscription(start, end) {
      var parsntage = TimeRight.remaningsubscription(start, end);
      return parsntage;
    },
    addsubscription() {
      this.addsubscriptionmodal = !this.addsubscriptionmodal;
    },
    checksubscription() {
      this.checksubscriptionmodal = !this.checksubscriptionmodal;
    },
  },
};
</script>

<style>
</style>