<template>
  <div class="card">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-4">
          <Tshirt :color="team.color" />
        </div>
        <div class="col-8">
          <h4 class="mb-0">{{ team.name }}</h4>
          <p class="mb-0">{{ team.sortname }}</p>
        </div>
        <div class="col-4">
          <p class="m-0">ID</p>
          <h5>
            <b>{{ team.id }}</b>
          </h5>
        </div>
        <div class="col-4">
          <p class="m-0">D11 ID</p>
          <h5>
            <b>{{ team.did }}</b>
          </h5>
        </div>
        <div class="col-4">
          <p class="m-0">Sport</p>
          <h5>
            <b>{{ team.sport }}</b>
          </h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12">
          <p class="m-0">Series</p>
          <span
            v-for="series in team.seriess"
            :key="series"
            class="badge bg-gradient-secondary"
            style="margin-right:3px"
          >{{ series.name }}</span>
        </div>
      </div>
      <div class="actionbtn" v-if="action">
        <div class="row">
          <div class="col-sm-6">
            <button
              type="button"
              class="btn bg-gradient-secondary w-100 mb-0 mt-3"
              @click="editteam"
            >Edit</button>
          </div>
          <div class="col-sm-6">
            <button
              type="button"
              class="btn bg-gradient-danger w-100 mb-0 mt-3"
              @click="deleteteam"
            >Delete</button>
          </div>
        </div>
      </div>
    </div>

    <TeamAddEditModal
      :key="id"
      :isnew="false"
      :active="active"
      :data="team"
      :sports="sports"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import database from "../../databasefuncation";

import Tshirt from "./Tshirt.vue";

import TeamAddEditModal from "../../components/teams/team_add_edit_modal.vue";

export default {
  props: [
    "team",
    "action",
    "sports"
  ],
  components: { Tshirt, TeamAddEditModal },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    async deleteteam() {
      await database.deletedoc("teams", this.team.id.toString());
    },

    editteam() {
      this.closeModal();
      // this.$router.push({ path: `/teams/edit-team/${this.id}` });
    },
    closeModal() {
      this.active = !this.active;
    },
  },
};
</script>

<style scoped>
</style>