<template>
    <div :class="{ 'modal fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }" :id="add_other_prediction" tabindex="-1" role="dialog" :aria-labelledby="add_other_prediction"
        aria-hidden="true">
        <div v-if="active" class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">{{ data.name }}</h6>
                    <!-- <div class="col d-flex align-items-center justify-content-end">
                        <select class="form-control mr-2" v-model="activeSection" required style="margin-right: 0.5rem !important;">
                            <option :value="null">Select Section</option>
                            <option value="lastFive">Last 5</option>
                            <option value="teamPerformance">Team Performance</option>
                        </select>
                        <button type="submit" class="btn bg-gradient-success mb-0">Add</button>
                    </div> -->
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <label>Section 1 Name</label>
                            <input v-model="data.data.lastFive.name" class="form-control">
                        </div>
                        <div class="col-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Team</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="team in data.data.lastFive.teams" :key="team">
                                        <td>
                                            <h6 :style="{ color: team.color }" class="mb-0 text-md"><b>{{ team.name }}</b></h6>
                                        </td>
                                        <td>
                                            <div class="d-flex gap-2">
                                                <select v-for="(select, index) in team.data" :key="select" v-model="team.data[index]" class="form-control">
                                                    <option v-for="optino in options" :key="optino">{{ optino }}</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 mb-2">
                            <label>Section 2 Name</label>
                            <input v-model="data.data.teamPerformance.name" class="form-control">
                        </div>
                        <div class="col-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Team</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Win</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Loss</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="team in data.data.teamPerformance.teams" :key="team">
                                        <td>
                                            <h6 :style="{ color: team.color }" class="mb-0 text-md"><b>{{ team.name }}</b></h6>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" v-model="team.win">
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" v-model="team.loss">
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" v-model="team.total">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button @click="modalclose('closeModal')" type="button" class="btn btn-link ml-auto mb-0" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div :class="{ 'modal-backdrop fade': true, show: active }" :style="{ 'display': active ? 'block' : 'none' }"></div>
</template>

<script>
import { LightEditor, LightEditorPlugin } from "@hannanmiah/light-editor"

import "@hannanmiah/light-editor/dist/style.css"

export default {
    props: ["index", "active", "data"],
    components: { LightEditor },
    data() {
        return {
            activeSection: null,
            options: ["-", "W", "L"]
        }
    },
    methods: {
        modalclose(funtext) {
            this.$emit(funtext, this.data, this.index);
        },
        async formSubmit() {
            this.modalclose("closeModal");
        },
    },
}
</script>

<style>
</style>